@import "src/styles/_base.module.scss";
.publishedOn {
  background-color: color('background');
  padding: rem(10);
  border-radius: 5px;
  color: color('gray');
  white-space: nowrap;
}

.jobSubHeading {
  display: inline-block;

  padding: rem(9 0 0 5);
}

.jobPostWrapper {
  padding-left: rem(10);
}
.colorFieldWrapper {
  display: inline-block;
  margin: rem(5 0 0 10);
}
.jobIndicatorFormWrapper {
  margin-top: rem(10);
  .acronymComp {
    padding-top: rem(10);
    display: flex;
    align-items: center;
  }
}
.submitButtonWrapper {
  margin-top: 106px;
  margin-bottom: 20px;
}
.jobPostStatusContentWrapper {
  margin-bottom: rem(20);
  button {
    padding-left: rem(14) !important;
    padding-right: rem(14) !important;
  }
}
.jobPublishedOnwrapper {
  padding-right: 10px;
}

@media screen and (max-width: 1350px) {
  .stack {
    // @include span(1);
    // @include stack();
    padding-left: 0;
    padding-right: 0;
  }
  .submitButtonWrapper {
    margin-top: 30px !important;
  }
}

.sectionLabel {
  padding: rem(5 0);
  color: color('dark blue');
  // float: left;
  margin-right: rem(5);
}
.sectionInput {
  padding: rem(5 0);
  width: rem(200);
  float: left;
  margin-right: rem(20);
}
.sectionAction {
  float: left;
}
.actions {
  //   @include cf;
  float: right;
}
.label {
  width: 100%;
  display: inline-block;
  text-align: right;
  vertical-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.input {
  width: rem(190);
  display: inline-block;
}

.fieldError {
  font-size: rem(12);
  color: color('red');
}
.avatar_dot {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 80px !important;
}

.mtMinus40 {
  @include from(xl) {
    // margin-top: -40px !important;
  }
}
:global {
  .DayPickerInput {
    input {
      width: 100%;
    }
  }
}
