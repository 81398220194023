@import "src/styles/_base.module.scss";
.doubleRightPadding {
  padding-right: rem(20);
}
.publishedOn {
  background-color: color('background');
  padding: rem(10);
  border-radius: 5px;
  color: color('gray');
  white-space: nowrap;
}
.jobSubHeading {
  display: inline-block;
  padding: rem(9 0 0 5);
}

.jobPostWrapper {
  padding-left: rem(10);
}
.jobIndicatorFieldWrapper {
  margin: rem(0 0 15 15);
}

.jobIndicatorFormWrapper {
  margin-top: rem(10);
  .avatar_dot {
    width: auto;
    display: inline-block;
    float: left;
  }
  .acronymComp {
    padding-top: rem(10);
  }
}
.submitButtonWrapper {
  margin-top: 106px;
  margin-bottom: 20px;
}
.jobPostStatusContentWrapper {
  margin-bottom: rem(20);
  button {
    padding-left: rem(14) !important;
    padding-right: rem(14) !important;
  }
}
.jobPublishedOnwrapper {
  padding-right: 10px;
}

@media screen and (max-width: 1350px) {
  .stack {
    // @include span(1);
    // @include stack();
    padding-left: 0;
    padding-right: 0;
  }
  .submitButtonWrapper {
    margin-top: 30px !important;
  }
}

.sectionLabel {
  padding: rem(5 0);
  color: color('dark blue');
  // float: left;
  margin-right: rem(5);
}
.sectionInput {
  padding: rem(5 0);
  width: rem(200);
  float: left;
  margin-right: rem(20);
}
.sectionAction {
  float: left;
}
.actions {
  @include cf;
  float: right;
}
.label {
  width: 100%;
  display: inline-block;
  text-align: right;
  vertical-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.input {
  width: rem(190);
  display: inline-block;
}

.fieldError {
  font-size: rem(12);
  color: color('red');
}

.jobsMUp {
  @include to(m) {
    display: none !important;
  }
}
.jobsMDown {
  @include from(l) {
    display: none !important;
  }
}

.jobPremiumPill {
  display: flex;
  @include from(m) {
    margin-top: 5px;
  }
  @include to(m) {
    font-size: rem(8) !important;
  }
}
.jobsTopRowMDown {
  display: flex;
  justify-content: space-between;
}

.applicantViewSwitch {
  display: flex;
  justify-content: end;
  align-items: center;
  :global {
    .viewBtn {
      background: transparent;
      border: none;
      color: color('gray');
      &.active {
        color: color('blue') !important;
      }
    }
    .filterBtn {
      @include from(m) {
        // display: none;
      }
      border: 1px solid;
      border-radius: 50%;
      display: flex;
      padding: 5px;
      color: color('blue');
    }
  }
}
.inputTitle {
  font-size: rem(14) !important;
}
.applicantsPage {
  @media screen and (max-width: 600px) {
    padding-top: rem(160) !important;
  }
}
.applicantsCounter {
  margin-left: 10px;
  font-size: rem(12);
  color: color('gray');
  @include to(s) {
    margin-left: 0;
  }
}
