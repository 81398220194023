@import "src/styles/_base.module.scss";
@mixin menuItem {
  position: relative;
  @include cf;
  a {
    color: color('dark blue');
    cursor: pointer;
    display: block;
    padding: rem(10);
    -webkit-font-smoothing: antialiased;
    font-size: rem(14);
    svg {
      color: color('light gray');
      margin-left: rem(5);
    }
    &:hover {
      color: color('dark blue');
      background: color('light blue');
      svg {
        color: color('dark blue');
      }
    }
  }
  &.menu-item-disabled {
    a {
      color: color('gray');
      cursor: not-allowed;
      &:hover {
        color: color('gray');
        background: color('mild');
      }
    }
  }
}

:global {
  .navigation {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
    @include to(l) {
      &.mobileNavActive {
        // height: 100vh;
      }
    }
  }
  .header {
    position: fixed;
    width: 100%;
    top: rem(50);
    left: 0;
    z-index: 19;
  }
  .filters-sidebar .header {
    z-index: 20 !important;
    // margin-left: 305px;
    margin-left: 0 !important;
  }
  .filters-sidebar .header .navbar .float-button {
    transform: rotate(90deg);
  }
  .search-page .sidebar {
    margin-top: rem(-60);
  }
}
:global {
  .navMenu {
    appearance: none;
    background: none;
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    line-height: rem(20);
    outline: none;
    cursor: pointer;
    &[type='button'] {
      appearance: none;
    }

    @include to(l) {
      &.mobileNavActive {
        // height: 100vh;
      }
    }
  }
}

.navBarItem {
  float: left;
  margin-right: rem(10);
  font-size: rem(14);
  :global {
    .card-title svg {
      vertical-align: middle !important;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.navBar {
  min-height: 58px;
  .navBarLeft {
    float: left;
    @include cf;
  }
  :global {
    .navitem > a,
    .navitem > .dropdown {
      color: color('gray');
      appearance: none;
      background: none;
      border: none;
      display: block;
      margin: 0;
      outline: none;
      cursor: pointer;
      padding: rem(15 10);
      line-height: rem(20);
    }
  }
  &.mainmenu {
    position: fixed !important;
    z-index: 999999 !important;
    width: 100%;
    padding: rem(0 20);
    background: #384677;
    box-shadow: 0 0 rem(2) rgba(color('dark blue'), 0.2);
    height: 50px;
    @include to(l) {
      &.mobileNavActive {
        height: 100vh !important;
        padding: 0;
        -webkit-transition: height 0.3s ease;
        -moz-transition: height 0.3s ease;
        -o-transition: height 0.3s ease;
        -ms-transition: height 0.3s ease;
        transition: height 0.3s ease;
      }
    }
    @include to(l) {
      justify-content: center;
      flex-direction: column;
      align-content: flex-start;
    }
    .navBarSegment {
      display: flex;
      align-items: center;
      @include to(l) {
        flex-direction: column;
        display: flex;
        width: 100%;
        align-items: start;
        &.mobileNavActive {
          height: 100%;
          margin-top: 30px;
          justify-content: center;
        }
      }
      :global {
        .navitem {
          &.active {
            opacity: 1;
            color: white;
          }
          @include to(l) {
            display: none;
          }
        }
      }
    }
    .left {
      :global {
        a.navitem,
        span.labeled-title {
          opacity: 0.5;
        }
      }
    }
    :global {
      .navitem > a,
      .navitem > .dropdown {
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        svg {
          opacity: 1;
        }
        .labeled-count {
          font-size: 100%;
          color: #6d789e;
          background: #2f3d6b;
        }
        &[aria-expanded='true'] {
          background: #2e3c6c !important;
          .labeled-count {
            font-size: 100%;
            color: #647097;
            background: #273461;
          }
        }
        &.active {
          color: color('white');
          &:after {
            content: ' ';
            position: absolute;
            border-left: rem(5) solid transparent;
            border-right: rem(5) solid transparent;
            border-bottom: rem(7) solid color('white');
            width: 0;
            height: 0;
            bottom: 0;
            left: calc(50% - 4px); //can't use rem inside calc
          }
        }
      }
    }
  }
  &.submenu {
    position: relative;
    // height: rem(60);
    padding: rem(0 20);
    background: #fff;
    box-shadow: inset 0 rem(-1) 0 color('light gray');
    .hasDropdown:after {
      border-top-color: color('gray');
    }
    .active {
      // box-shadow: inset 0 rem(-3) 0 color('blue');
      border-bottom: 3px solid color('blue');
      :global {
        .labeled-title {
          color: color('blue');
        }
        .labeled-count {
          color: color('blue') !important;
          background: color('light blue') !important;
        }
      }
    }
    .cardNav {
      padding: rem(10);
    }
    :global {
      .navitem > a,
      .navitem > .dropdown {
        padding: rem(20 0 25 0);
        margin: rem(0 10);
        line-height: rem(20);
        font-weight: 500;
        font-size: rem(16);
        -webkit-font-smoothing: antialiased;
        color: color('dark gray');
        .labeled-count {
          color: color('gray');
          background: rgba(color('light gray'), 0.5);
        }
        &:hover {
          color: color('blue');
        }
      }
    }
  }
}

.navBarSegment {
  :global {
    .navitem button:not(.dropdown) {
      color: color('gray');
    }
  }
  &.user {
    float: right;
    :global {
      .navitem > .dropdown {
        padding: rem(10);
        border-right: rem(1) solid #2d3a69;
      }
    }
  }
}

.menuDropdown {
  min-width: rem(320);
  background: #fff;
  border: rem(1) solid color('light gray');
  border-radius: rem(3);
  box-shadow: 0 rem(2) rem(15) rgba(color('dark gray'), 0.3);
  padding: rem(5 0);
  &[data-reactroot] {
    margin-top: rem(3);
  }
  &.hasHeading {
    padding-top: 0;
  }
  :global {
    .section-title {
      box-shadow: none !important;
    }
    .dropdown {
      box-shadow: none;
      padding: 0;
      border: none;
      position: relative;
      appearance: none;
      a {
        cursor: pointer;
        display: block;
        padding: rem(10);
        color: color('dark blue');
        font-weight: 500;
        font-size: rem(14);
      }
      svg {
        position: absolute;
        top: 50%;
        right: rem(10);
        margin-top: rem(-8);
        color: color('gray');
        // transform: rotate(-90deg);
      }
      &:hover {
        a,
        svg {
          color: color('dark blue');
        }
      }
    }
    .menu-item {
      @include menuItem;
    }
    .menu-item-slot {
      display: block;
      padding: rem(5 10 10 10);
      :global {
        label:not(.checkbox-label) {
          padding-top: rem(6);
          padding-bottom: rem(6);
        }
        label.checkbox-label {
          line-height: rem(20);
        }
        .form-element input {
          padding-top: rem(6);
          padding-bottom: rem(6);
        }
      }
    }
  }
}

:global {
  .main-menu-dropdown[data-reactroot] {
    padding-top: rem(5);
    padding-bottom: rem(5);
  }
}

.subMenu {
  min-width: rem(250);
  padding: rem(10 0);
  margin-top: rem(-10);
  margin-left: rem(-5);
  z-index: 111;
  background: #fff;
  border: rem(1) solid color('light gray');
  box-shadow: 0 rem(2) rem(15) rgba(color('dark gray'), 0.3);
  :global {
    .menu-item {
      @include menuItem;
    }
    .menu-item-slot {
      display: block;
      padding: rem(0 10);
    }
  }
}

.menuDivider {
  border-top: rem(1) solid color('mild');
  margin: rem(5 0 10 0);
}
.menuAction {
  padding: rem(0 10 5 10);
  :global {
    .form-elements,
    .form-element {
      margin: 0;
      font-size: rem(14) !important;
    }
  }
}

.dropdownTrigger {
  position: relative;
  display: flex;
  align-items: center;
  @include cf;
  > div {
    float: left;
  }
  > svg {
    // position: absolute;
    // top: 50%;
    // margin-top: rem(-10);
    // right: 0;
    // opacity: 0.5;
    margin-left: 5px;
  }
  &:hover {
    > svg {
      opacity: 1;
    }
  }
  :global {
    .sb-avatar {
      display: block !important;
    }
  }
  &.noCaret {
    padding-right: 0;
  }
}

.menuItemsScroll {
  max-height: rem(310);
  overflow-y: auto;
  
}

.menuItemCard {
  :global {
    .card-titles a {
      padding: rem(5 10) !important;
      h3 {
        font-size: rem(14);
        font-weight: 500;
      }
      &:hover h3 {
        color: color('dark blue') !important;
      }
    }
  }
}

.cogIcon svg {
  color: color('gray');
}

.navBarItem {
  position: relative;
  @include to(l) {
    &.mobileNavActive {
      width: 100%;
      display: block !important;
      border-bottom: 1px solid color('gray');
      margin-right: 0;
      a {
        font-size: 1rem;
      }
    }
  }
}

// nav toggle on mobile
#menuToggle {
  display: none;
}

@include to(l) {
  #menuToggle {
    position: absolute;
    top: 10px;
    left: 20px;
    display: block;
    /* GENERAL STYLES */
    .visuallyHidden {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    .burger {
      margin: 0 auto;
      width: 27px;
      height: 27px;
      position: relative;
    }

    .burger .bar {
      padding: 0;
      width: 30px;
      height: 4px;
      background-color: #fff;
      display: block;
      border-radius: 4px;
      transition: all 0.4s ease-in-out;
      position: absolute;
    }

    .bar1 {
      top: 03px;
    }

    .bar2,
    .bar3 {
      top: 13.5px;
    }

    .bar3 {
      right: 0;
    }

    .bar4 {
      bottom: 0;
    }

    /* BURGER 4 */

    .burger2 .bar2,
    .burger3 .bar3,
    .burger4 .bar4 {
      top: 13.5px;
    }

    .burger4 .bar5 {
      bottom: 0px;
    }

    .burger4 .bar {
      transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
    }

    .burger4 .bar2 {
      width: 1px;
      transform: rotate(90deg);
      left: 13.5px;
    }

    .burger4 .bar3 {
      width: 1px;
      left: 13.5px;
    }

    .checkbox4:checked + label > .burger4 > .bar1 {
      top: 13.5px;
      background-color: transparent;
    }

    .checkbox4:checked + label > .burger4 > .bar2 {
      left: 0px;
      width: 30px;
      transform: rotate(45deg);
    }

    .checkbox4:checked + label > .burger4 > .bar3 {
      left: 0;
      width: 30px;
      transform: rotate(-45deg);
    }

    .checkbox4:checked + label > .burger4 > .bar4 {
      background-color: transparent;
    }

    .checkbox4:checked + label > .burger4 > .bar5 {
      bottom: 13.5px;
      background-color: transparent;
    }
  }
}
.tmrHeadingMobile {
  @include to(l) {
    display: block;
  }
  display: none;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  margin-bottom: 0;
}
