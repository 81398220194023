@import "src/styles/_base.module.scss";
.notesFormWrapper {
  padding: 0;
  margin-bottom: rem(10);
  :global {
    .form-elements {
      margin: 0;
    }
    textarea {
      resize: none !important;
      outline: none !important;
      border-radius: rem(3 3 0 0) !important;
    }
  }
}

.fileInput {
  margin-bottom: rem(20);
  :global {
    .form-file {
      border-top: none;
      border-radius: rem(0 0 3 3);
    }
  }
}

.messageFocused,
.messageFocused textarea {
  height: rem(150);
}
