@import "src/styles/_base.module.scss";
.inviteWrapper {
  text-align: center;
  margin-bottom: rem(10);
}

.cardIcon {
  display: inline-block;
  width: rem(40);
  height: rem(40);
  background: color('light blue');
  border-radius: 50%;
  padding: rem(10);
  margin-right: rem(-5);
  svg {
    color: color('blue');
  }
}

.hospitalityIcon {
  background: #384677;
  width: rem(40);
  height: rem(40);
  border-radius: 50%;
  display: inline-block;
  padding: rem(10);
  color: #fff;
  margin-left: rem(-5);
  svg {
    width: rem(30);
    height: rem(30);
    margin: rem(-5);
  }
}
