@import "src/styles/_base.module.scss";
.talentSection {
  .talentSectionTitle {
    background: color('mild');
    box-shadow: inset 0 -1px 0 rgba(color('light gray'), 0.7);
    padding: rem(6 20);
    text-transform: uppercase;
    position: relative;
    h4 {
      color: color('gray');
      font-size: rem(11);
      text-transform: uppercase;
      font-weight: 700;
      span span {
        padding: 0;
      }
      :global {
        .count {
          background: rgba(color('light gray'), 0.5);
          font-size: 100%;
        }
      }
    }
  }
  .sectionAction {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(6 20);
    a {
      font-size: rem(12);
      color: color('blue');
      text-transform: none;
    }
  }
  .isToggleable {
    cursor: pointer;
    &:hover {
      span span {
        color: color('blue');
      }
    }
  }
  .invisibleContent {
    display: none;
  }
}

.toggleArrow {
  float: right;
  margin-top: rem(-16);
  transform: rotate(90deg);
  transition: all ease 0.3s;
  &.isOpen {
    transform: rotate(-90deg);
  }
}
