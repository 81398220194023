@import "src/styles/_base.module.scss";
.imageCheckboxWrapper {
  display: flex;
  flex-wrap: wrap;

  .imageCheckbox {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    padding: 0.25rem;
    margin: 0 0.15rem 0.15rem 0;
    max-width: 250px;
    @include to(m) {
      width: 100%;
      max-width: unset;
    }

    &:last-of-type {
      margin: 0 0 0.15rem 0;
    }

    label {
      display: block;
      position: relative;
      border: 1px solid #ddd;
      widows: 100%;
      height: 3.5rem;
      line-height: 3.5rem;
      -webkit-border-radius: rem(3);
      -moz-border-radius: rem(3);
      border-radius: rem(3);
      overflow: hidden;

      &:before {
        bottom: 0;
        right: 0;
        left: auto;
        top: auto;
        border: 1px solid transparent;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }

      &:after {
        bottom: 0.25rem;
        right: 0.38rem;
        left: auto;
        top: auto;
      }

      &.checked {
        border: 1px solid color('blue');
        &:after {
          border: solid #fff;
          border-width: 0 0.125rem 0.125rem 0;
        }
        &:before {
          background: color('blue');
          border: 1px solid color('blue');
        }
      }
    }
  }
}

.imgCheckbox {
  display: inline-block;
  background: #eee;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 2.7rem;
  width: 2.7rem;
  position: absolute;
  overflow: hidden;
  text-align: center;
  line-height: 2.7rem;
  left: 0.2rem;
  top: 0.3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgCheckboxLabel {
  display: inline-block;
  margin-left: 1.4rem;
  padding: 0 0.25rem 0 0;
}

.imgCheckboxWrapper {
  display: inline-block;
  position: relative;
}

.imageCheckboxinput {
  display: none;

  &:checked + label {
    border: 1px solid color('blue');
    &:after {
      border: solid #fff;
      border-width: 0 0.125rem 0.125rem 0;
    }
    &:before {
      background: color('blue');
      border: 1px solid color('blue');
    }
  }
}
