@import "src/styles/_base.module.scss";
.imgUploadPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid color('light gray');
}

.imgUploadPreviewCover {
  outline: 4px solid color('blue');
  border-radius: 4px;
}

.imgUploadPreview {
  max-width: 190px;
  max-height: 115px;
  position: relative;
  height: 100%;
  cursor: pointer;

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &:hover {
    outline: 4px solid color('blue');
    border-radius: 4px;
  }
}

.fileInput {
  margin-bottom: rem(10);

  :global {
    .form-file {
      border-top: none;
      border-radius: rem(0 0 3 3);
    }
  }
}

.setAsCover {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  font-size: 12px;
  background: color('blue');
  color: color('white');
  font-weight: 700;
  font-family: $font-family;

  &:hover {
    color: color('white') !important;
  }
}

.imageUploadDelete {
  position: absolute;
  top: 5px;
  right: 5px;
  color: color('red');
  background: white;
  display: flex;
  border-radius: 50%;

  &:hover {
    color: red !important;
  }
}

:global {

  .form-elements,
  .form-element {
    // margin-bottom: 0 !important;
  }

  .form-element {
    background-size: cover;
    background-position: center center;
    background-blend-mode: lighten;
  }
}

.dropInnerAttachement {
  padding: rem(10);
  display: flex;
  justify-content: start;
  align-items: center;
  color: color('dark gray');

  &:hover {
    color: color('blue');
    cursor: pointer;
  }
}

.attachmentName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}