@import "src/styles/_base.module.scss";
.createNewTeam {
  display: flex;
  flex-direction: column;
  width: 334px;
  background: #e5f5fc;
  height: 138px;
  justify-content: center;
  align-items: center;
  color: color('blue');
  cursor: pointer;
  font-weight: 600;
  font-family: $font-proxima;
  div {
    font-size: 20px;
  }
}
