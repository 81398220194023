@import "src/styles/_base.module.scss";
.listWrapper {
  position: relative;
  display: block;
  margin-bottom: 100px;
  overflow: auto;
  width: 100%;

  .card_container {
    margin-top: 8%;
  }
}
