@import "src/styles/_base.module.scss";
.contextMenuWrapper {
  @include cf;
  border: 1px solid color('light gray');
  background: #fff;
  display: none;
  &.visible {
    display: flex;
  }
  .contextMenuItem {
    border-right: 1px solid color('light gray');
    display: flex;
    justify-content: center;
    align-items: center;
    :global {
      .dropdown[aria-expanded='true'] {
        background: color('background');
      }
      .form-elements button {
        padding: rem(5);
      }
    }
    svg {
      color: color('gray');
    }
    &:hover {
      svg {
        color: color('blue');
      }
    }
    &:last-child {
      border: none;
    }
  }
}
