@import "src/styles/_base.module.scss";
.cardNav {
  margin: rem(7 0 8);
}

.backLink {
  border-right: rem(1) solid color('light gray');
  a {
    font-size: rem(16) !important;
    font-weight: 500 !important;
    color: color('gray') !important;
    svg {
      float: left;
      margin-right: rem(0);
      margin-top: rem(-1);
      color: color('gray') !important;
    }
    &:hover {
      color: color('blue') !important;
      svg {
        color: color('blue') !important;
      }
    }
  }
}

.cancelLink {
  a {
    color: color('blue') !important;
    font-weight: 500;
  }
}

.search {
  :global {
    .navbar {
      background: none !important;
      box-shadow: none !important;
    }
  }
}

.filtersButton {
  margin-top: rem(10);
  position: relative;
  left: rem(0);
}

.heading {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: $font-proxima;
}

.large {
  // h1
  line-height: rem(25);
  font-size: rem(20);
  font-weight: 700;
}

.medium {
  // h2
  line-height: rem(25);
  font-size: rem(20);
  font-weight: 600;
}

.small {
  // h3
  line-height: rem(20);
  font-size: rem(16);
  font-weight: 600;
}

.xsmall {
  // h4
  line-height: 1;
  letter-spacing: 1px;
  font-size: rem(12.5);
  font-weight: 700;
}
