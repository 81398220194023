$font_path: '../static/fonts/' !default;

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_path}sourcesanspro-bold-webfont.woff2') format('woff2'),
       url('#{$font_path}sourcesanspro-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_path}sourcesanspro-semibold-webfont.woff2') format('woff2'),
       url('#{$font_path}sourcesanspro-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_path}sourcesanspro-regular-webfont.woff2') format('woff2'),
       url('#{$font_path}sourcesanspro-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


