@import "src/styles/_base.module.scss";
.usersDropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
  }
}
.contactName {
  font-size: 14px;
  font-family: $font-proxima;
  font-weight: 600;
}
