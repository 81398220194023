@import "src/styles/_base.module.scss";
.overlayWrapper {
  // background: rgba(#fff, .95);
  background: rgba(color('background'), 0.95);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  overflow: auto;
  &.opened {
    display: block;
  }

  .overlayClose {
    position: fixed;
    top: rem(20);
    right: rem(20);
    z-index: +1;
    cursor: pointer;
    padding: rem(10);
    border-radius: 50%;
    svg {
      color: color('blue');
    }
    &:hover {
      background: rgba(color('blue'), 0.2);
    }
  }

  .overlayContainer {
    float: right;
    position: relative;
    left: -50%;
    margin-bottom: rem(80);
    &.fullSize {
      width: 100%;
    }
    .overlayInner {
      position: relative;
      left: 50%;
      &.fullSize {
        max-width: 100%;
      }
    }
  }
  :global {
    .client-profile {
      border-radius: rem(3);
      border: rem(1) solid color('light gray');
      box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
    }
  }
}
