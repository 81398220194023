@import "src/styles/_base.module.scss";
.inputWrapper {
  margin: rem(5 0 35);
  display: flex;
}

.logoDisplayWrapper {
  .jobLogoImage {
    border-radius: 50%;
    width: rem(110);
    height: rem(110);
    @include to(l) {
      width: rem(60);
      height: rem(60);
    }
  }
}

.uploadButtonContainer {
  margin: 0 0 0 rem(20);
  @include to(l) {
    margin: 0 0 0 rem(8);
  }
  .uploadContentWrapper {
    margin: rem(5 10 10 10);
  }
}
