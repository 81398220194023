@import "src/styles/_base.module.scss";
.jobIndicatorWrapper {
  // height: rem(80);
  // width: rem(80);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color('gray');
  border-radius: 50%;
  position: relative;
  margin-left: rem(-5);
  @include to(m) {
    height: rem(25) !important;
    width: rem(25) !important;
  }
}

.jobIndicatorContent {
  // @include align(both);
  color: color('white');
  // font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  @include to(m) {
    font-size: 0.7rem;
  }
}
.jobHeaderIndicator {
  height: rem(50) !important;
  width: rem(50) !important;
}
