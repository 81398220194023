@import "src/styles/_base.module.scss";
.wrapper_input {
  position: relative;
  .closeInput {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }
  .checkInput {
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;
  }
  .btnBack {
    position: absolute;
    right: 47px;
    top: 10px;
    cursor: pointer;
  }
}

.deleted {
  display: none;
}

.wrapper {
  background-color: #f2f2f2;
  border-radius: 7px;
  display: flex;
  padding: 15px 20px;
  color: #333333;
  margin-bottom: 10px;
  .title {
    font-size: 14px;
    margin-left: 20px;
    font-style: italic;
  }
  .sort {
    margin-right: 20px;
  }
  .edit {
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
  }
  .check {
    // margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
  }
  .close {
    cursor: pointer;
  }
  .sort,
  .edit,
  .close .check {
    color: #a1a1a1;
  }
}
