@import "src/styles/_base.module.scss";
.iconContainer {
  // width: rem(100);
  display: flex;
  position: relative;
  margin-right: 7px;
  align-items: center;
  // display: none;
}
.titleContainer {
  box-sizing: border-box;
  width: auto;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: rem(550);
  // flex-direction: column;
  justify-content: start;
  @include to(l) {
  }
}
.parentWrapper {
  display: flex;
  align-items: center;

  @include from(s) {
    width: 550px;
  }
}
.titleLink {
  cursor: pointer;
}
