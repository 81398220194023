@import "src/styles/_base.module.scss";
.sidebarPageWrapper {
  position: relative;
  overflow-x: hidden;
  &.opened {
    :global {
      .mainPanelRight {
        @include from(s) {
          padding-left: rem(350);
        }
      }
      .header {
        z-index: 10;
        left: 350px;
      }
    }
  }
  @include to(m) {
    &.opened {
      position: absolute;
      left: 0;
      padding-left: rem(0);
      :global {
        .header {
          z-index: 10;
        }
      }
    }
  }
}

.sideBar {
  position: fixed;
  top: rem(110);
  left: rem(-360);
  width: rem(350);
  bottom: 0;
  z-index: 11;
  background: #fff;
  border-right: rem(1) solid color('light gray');
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  overflow-y: auto;
  // transition: all 300ms;
  transition: left 200ms linear;
  margin-top: -3.75rem;
  &.opened {
    left: rem(0);
  }
  @include to(m) {
    width: rem(275);
  }
}

.filters {
  :global {
    .header {
      z-index: 20 !important;
    }
  }
}

.sideBarClose {
  right: rem(10);
  top: rem(10);
  cursor: pointer;
  color: color('gray');
  position: absolute;
  z-index: +2;
}
