@import "src/styles/_base.module.scss";
.card {
  height: rem(290);
}
.flagLinkContainer {
  margin-top: rem(10);
  height: rem(14);
}

.firstHeading {
  line-height: rem(20);
  height: rem(20);
  overflow: hidden;
  small {
    font-size: 60%;
    margin-left: rem(5);
    color: color('dark gray');
  }
}

.secondHeading {
  line-height: rem(15);
  height: rem(30);
  overflow: hidden;
  color: color('gray');
  font-size: rem(14);
  margin: rem(5 0);
  margin-bottom: rem(10);
}

.avatarLink {
  cursor: pointer;
}
.img_avatar {
  margin-bottom: 10px;
}

.applicantCard {
  max-width: 280px;
  center {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.maxWidth240 {
  max-width: 280px !important;
}
