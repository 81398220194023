@import "src/styles/_base.module.scss";
.noteItemWrapper {
  width: 100%;
  .noteItem {
    padding: rem(20);
    border-bottom: 1px solid color('light gray');
    position: relative;
  }
  .noteMessage {
    color: color('gray');
    font-size: rem(14);
    line-height: 1.4;
    margin-bottom: rem(10);
  }
  .noteAttachments {
    margin-bottom: rem(10);
  }
  .noteAttach {
    background: color('light blue');
    padding: rem(10);
    border-radius: rem(3);
    margin-bottom: rem(5);
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: color('light blue');
    &:hover {
      color: color('blue');
    }
  }
  .columnHeaderMenu {
    position: absolute;
    top: rem(30);
    right: rem(10);
    padding: rem(10 0 10 30);
    margin-top: rem(-20);
    svg {
      color: color('gray');
    }
    &:hover {
      svg {
        color: color('dark gray') !important;
      }
    }
  }
}
