@import "src/styles/_base.module.scss";
.menuButton {
  cursor: pointer;
  svg {
    display: block;
    color: color('light gray');
  }
  &:hover svg {
    color: color('blue');
  }
}

.current a {
  color: color('dark gray') !important;
}
