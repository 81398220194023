@import "src/styles/_base.module.scss";
.cardWrapper {
  background: #fff;
  position: relative;
  &.isTeamCard {
    padding: 25px;
  }
  &.isStandalone {
    margin: rem(0 20 20 0);
    width: rem(364);
    float: left;
    border: rem(1) solid color('light gray');
    border-radius: rem(2);
    box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
    @include to(m) {
      width: rem(310);
    }
  }
  &.small {
    .headerWrapper {
      padding: rem(5);
    }
  }
  &.medium {
    .headerWrapper {
      padding: rem(10);
    }
  }
  &.large {
    .headerWrapper {
      padding: rem(20);
    }
  }
  &:hover {
    :global {
      .card-menu .dropdown {
        display: block;
      }
    }
  }
}

.headerWrapper {
  position: relative;
  h3 {
    color: color('dark blue');
  }
  h4 {
    color: color('gray');
  }
  a {
    display: block;
    @include cf;
    &:hover {
      h3 {
        color: color('blue');
      }
      h4 {
        color: color('light gray');
      }
    }
  }
}

.settingsLinkWrapper {
  position: absolute;
  top: rem(20);
  right: rem(25);
  cursor: pointer;
  svg path {
    color: color('light gray');
  }
  &:hover {
    svg path {
      color: color('blue');
    }
  }
}
