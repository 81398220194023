@import "src/styles/_base.module.scss";
// @import "base";

.jobImage{
  display: inline-block;
  width: 150px;
  height: 80px;
  $radius:3px;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  overflow:hidden;
  margin-right: 5px;
  position: relative;
  border: 1px solid #EEE;
  
  
  & > img{
    width: 150px;
    height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.jobCoverImage{
  @extend .jobImage;

  &:before{
    content:'COVER PHOTO';
    display: block;
    position:absolute;
    padding: 1px 10px 0px 10px;
    bottom: 2px;
    left: 2px;
    background: color('blue');
    font-weight: 600;
    color: #FFF;
    font-size: 0.7rem;
  }
}

.companyLogoWrapper{
  padding: 15px 0px;
}

.companyLogo{
  $radius:115px;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  height: $radius;
  width: $radius;
  overflow: hidden;
  border: 1px solid #DDD;

  img{
    display: block;
    width: 100%;
    height: auto;
  }
}

.companyPhotos{
  padding: 15px 0px 0px 0px;
}

.textSmallPadding{
  @extend .textSmall;
  padding: 10px 0px 0px 0px;
}

.membershipGradeItem{
  background: #f6f8fa;
  padding: .5rem;
  -webkit-border-radius: .2rem;
  -moz-border-radius: .2rem;
  border-radius: .2rem;
  display: inline-block;
  margin: 0 .25rem .25rem 0;
  span{
    display: inline-block;
    vertical-align: middle;
    &:last-of-type{
      padding: 10px;
    }
  }
}

.membershipGradeBadge{
  display: inline-block;
  background: #eee;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  height: 2.7rem;
  width: 2.7rem;
  overflow: hidden;
  text-align: center;
  line-height: 2.7rem;
  left: 0.3rem;
  top: 0.3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}