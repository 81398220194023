@import "src/styles/_base.module.scss";
.QuillEditor {
  margin: 10px 0px;
  padding: 0px;
  border: 1px solid #ddd;

  & > div {
    border: 1px solid #eee;
    display: block;

    &:nth-of-type(1) {
      background: #f6f8fa;
      padding: 5px 5px 0px 5px;
    }
    &:nth-of-type(2) {
      // background: black;
      padding: 5px 5px 0px 5px;
      min-height: 150px;
    }

    & > div {
      &:focus {
        border: none;
        outline: none;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }

    & > span {
      display: inline-block;

      & > select {
        display: none;
        position: absolute;
        height: 0px;
        width: 0px;
      }

      & > span {
        display: none;
        position: absolute;
        height: 0px;
        width: 0px;
      }

      & > button {
        background: none;
        border: none;

        background: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 35px;
        padding: 0px 9px 4px 9px;
        width: 35px;

        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        border: 1px solid transparent;

        &:hover {
          border: 1px solid #ddd;
          background: #eee;
        }

        & > svg {
          & > path {
            fill: none;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2;
          }

          & > line {
            fill: none;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2;
          }

          & > rect {
            fill: #bbb;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1;
          }
        }
      }
    }

    & > div {
      padding: 5px 8px;
      color: #7c8990;
      font-family: $font-family !important;
      & > p {
        margin: 3px 0px 3px 0px;
        color: #7c8990;
        font-size: rem(14) !important;
      }
      & > input {
        display: none;
      }
    }
  }
}
.QuillEditormedia {
  margin: 10px 0px;
  padding: 0px;
  border: 1px solid #ddd;

  & > div {
    border: 1px solid #eee;
    display: block;

    &:nth-of-type(1) {
      background: #f6f8fa;
      padding: 5px 5px 0px 5px;
    }
    &:nth-of-type(2) {
      // background: black;
      padding: 5px 5px 0px 5px;
      min-height: 50px;
    }
    & > div {
      &:focus {
        border: none;
        outline: none;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }
    & > span {
      display: inline-block;

      & > select {
        display: none;
        position: absolute;
        height: 0px;
        width: 0px;
      }

      & > span {
        display: none;
        position: absolute;
        height: 0px;
        width: 0px;
      }

      & > button {
        background: none;
        border: none;

        background: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 35px;
        padding: 0px 9px 4px 9px;
        width: 35px;

        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        border: 1px solid transparent;

        &:hover {
          border: 1px solid #ddd;
          background: #eee;
        }

        & > svg {
          & > path {
            fill: none;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2;
          }

          & > line {
            fill: none;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2;
          }

          & > rect {
            fill: #bbb;
            stroke: #bbb;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1;
          }
        }
      }
    }

    & > div {
      padding: 5px 8px;
      color: #7c8990;
      font-family: $font-family !important;
      & > p {
        margin: 3px 0px 3px 0px;
        color: #7c8990;
        font-size: rem(14) !important;
      }
      & > input {
        display: none;
      }
    }
  }
}
.description {
  @include defaultDescriptionStyle;
}
