@import "src/styles/_base.module.scss";
@include to(l) {
  .navAvatar {
    &.mobileNavActive {
      position: relative;
    }
    position: absolute;
    right: -5px;
    top: 0px;
    display: block !important;
  }
}

.mainNav {
  .navItem {
    &.active {
      color: color('white') !important;
      opacity: 1 !important;
      &:after {
        content: ' ';
        position: absolute;
        border-left: rem(5) solid transparent;
        border-right: rem(5) solid transparent;
        border-bottom: rem(7) solid color('white');
        width: 0;
        height: 0;
        bottom: 0;
        left: calc(50% - 4px); //can't use rem inside calc
      }
    }
  }
}
