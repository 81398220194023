@import "src/styles/_base.module.scss";
.candidateTable {
  margin-top: 150px;
  margin-left: 80px;
  margin-right: 80px;
  @include to(s) {
    margin-top: 190px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.tableIcon {
  font-size: 25px;
}
.subheading {
  font-size: rem(14);
  color: color('dark gray');
  font-family: $font-proxima;
}
