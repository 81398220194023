@import "src/styles/_base.module.scss";
.wizardParent {
  display: flex;
  justify-content: space-between;
  @include to(m) {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .wizardLink {
    text-align: center;
    @include to(m) {
      width: 90px;
    }
    a {
      font-weight: 600;
      color: color('dark gray');
      opacity: 0.3;
      font-size: rem(16);
      @include to(m) {
        font-size: rem(12);
      }
    }
    position: relative;
    &::after {
      content: '';
      height: 12px;
      width: 12px;
      background: color('light gray');
      border-radius: 50%;
      display: block;
      position: absolute;
      bottom: -25px;
      right: 50%;
      outline: 5px solid white;
      box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -webkit-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -moz-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      @include to(m) {
        bottom: -25px;
      }
    }
  }
  .wizardLink:nth-child(1) {
    margin-right: 100px;
    @include to(m) {
      margin-right: 30px;
    }

    &::after {
      content: '';
      height: 12px;
      width: 12px;
      background: color('blue');
      border-radius: 50%;
      display: block;
      position: absolute;
      bottom: -25px;
      right: 50%;
      outline: 5px solid white;
      box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -webkit-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -moz-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      @include to(m) {
        bottom: -25px;
      }
    }
  }
  .wizardLink:nth-child(2) {
    margin-right: 70px;
    @include to(m) {
      margin-right: 30px;
    }

    &::after {
      content: '';
      height: 12px;
      width: 12px;
      background: color('blue');
      border-radius: 50%;
      display: block;
      position: absolute;
      bottom: -25px;
      right: 50%;
      outline: 5px solid white;
      box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -webkit-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      -moz-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
      @include to(m) {
        bottom: -25px;
      }
    }
  }
}

.activeStep {
  color: color('dark gray') !important;
  opacity: 1 !important;
  font-weight: 500;
  font-size: rem(16);

  &::before {
    content: '';
    height: 12px;
    width: 12px;
    background: color('blue') !important;
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 500;
    bottom: -25px;
    right: 50%;
    outline: 5px solid color('light blue');
    box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(100, 100, 100, 0.75);
    @include to(m) {
      bottom: -25px;
    }
  }
}

.underliners {
  &::after {
    content: '';
    background: color('blue');
    height: 2px;
    width: 236px;
    // width: 176px;
    display: block;
    position: absolute;
    bottom: -20px;
    left: -162px;
    z-index: 501;
    @include to(m) {
      width: 133px;
      left: -87px;
      bottom: -20px;
    }
  }
}
.jobUnderliners {
  &::after {
    content: '';
    background: color('blue');
    height: 2px;
    width: 175px;
    // width: 176px;
    display: block;
    position: absolute;
    bottom: -20px;
    left: -150px;
    z-index: 501;
    @include to(m) {
      width: 133px;
      left: -87px;
      bottom: -20px;
    }
  }
}
