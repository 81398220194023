@import "src/styles/_base.module.scss";
.dividerWrapper {
  background: color('background');
  .heading {
    color: color('gray');
    letter-spacing: 0px;
    font-size: rem(11);
    text-transform: uppercase;
    padding: rem(10 10);
    line-height: rem(20);
  }
  :global {
    .labeled-count {
      background: rgba(color('gray'), 0.15);
      font-size: rem(10);
    }
  }
  &.large .heading {
    letter-spacing: 1px;
    font-size: rem(12);
    padding: rem(10 10);
  }
}
