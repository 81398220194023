@import "src/styles/_base.module.scss";
.statsContainerWrapper {
  margin-bottom: rem(20);
}

.statWrapper {
  display: block;
}

.statValue {
  color: color('dark gray');
  font-size: rem(30);
}

.statLabel {
  color: color('gray');
  font-size: rem(12);
}
