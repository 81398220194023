@import "src/styles/_base.module.scss";
.projectCard {
  &:hover {
    cursor: pointer;
  }
  display: inline-flex;
}
.contentWrapper {
  box-shadow: inset 0 rem(2) rem(2) rgba(color('gray'), 0.2);
  padding: rem(10);
  @include cf;
  :global {
    .role {
      float: right;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .candidates {
    color: color('gray');
    line-height: rem(20);
    float: left;
    font-size: rem(14);
    &:hover {
      color: color('light gray');
    }
  }
}
