@import "src/styles/_base.module.scss";
@include to(m) {
  .overlay {
    background-color: color('dark blue');
    opacity: 0.5;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }
}
