@import "src/styles/_base.module.scss";
// @import '~normalize.css/normalize';
@import '_base.module';
@import '_fonts';

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: color('background');
  font-family: $font-family;
  font-weight: 400;
  font-size: rem(16);
}
* {
  outline: none;
}
svg {
  // vertical-align: bottom !important;
}
div.card-titles svg {
  vertical-align: middle !important;
}

button,
input,
textarea,
select {
  font-family: $font-family;
}

a {
  text-decoration: none;
  &:hover {
    color: color('blue');
  }
}
