// @import "~jeet/scss/jeet/index";
@import 'breakpoint-slicer';
/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/

$rem-base: 16px;
$font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-proxima: 'Proxima Nova', Helvetica, Arial, sans-serif;
$font-serif: 'Book Antiqua', 'Times New Roman', Garamond, Didot, serif;

$palette: (
  'gray': #b5bdc2,
  'light gray': #dce0e3,
  'dark gray': #7c8990,
  'very dark gray': #666666,
  'blue': #32a3fd,
  'light blue': #d6edff,
  'dark blue': #272e41,
  'green': #7dc81b,
  'light green': #def1c6,
  'dark green': #89cd31,
  'red': #fd3732,
  'light red': #fecdcc,
  'dark red': #fd3732,
  'mild': #edeff1,
  'background': #f6f8fa,
  'white': #fff,
);

@function color($color: 'gray') {
  @return map-get($palette, $color);
}

@mixin transition($time: 0.5s) {
  transition: all $time ease;
}


@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  }
  @return $value;
}

@function data($attr) {
  @if $namespace {
    @return '[data-' + $namespace + '-' + $attr + ']';
  }

  @return '[data-' + $attr + ']';
}

@function rem($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

@mixin defaultInputStyle() {
  padding: rem(9);
  background: #fff;
  border-radius: rem(2);
  font-size: rem(14);
  line-height: rem(20);
  appearance: none;
  border: rem(1) solid color('light gray');
  color: color('dark gray');
  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
    border-color: color('blue');
  }
  &::placeholder {
    color: color('gray');
  }
}

@mixin defaultButtonStyle($size: 'normal') {
  appearance: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  border: none;
  background: transparent;
  @if ($size == 'normal') {
    border-radius: rem(3);
    padding: rem(11 20);
    font-size: rem(16);
  }
  @if ($size == 'small') {
    border-radius: rem(3);
    padding: rem(8 20);
    font-size: rem(14);
  }
  &:active,
  &:focus {
    outline: none;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

@mixin defaultLabelStyle() {
  font-weight: 500;
  // -webkit-font-smoothing: antialiased;
  color: color('dark blue');
  font-size: rem(14);
}

@mixin defaultDescriptionStyle() {
  line-height: 1;
  margin: rem(4) 0 0 0;
  font-weight: 400;
  font-size: rem(14);
  color: color('gray');
}

// BASE TEXT STYLES
h1 {
  font-weight: normal;
  font-size: rem(26);
  line-height: rem(30);
}
h2 {
  font-weight: bold;
  font-size: rem(20);
  line-height: rem(24);
}
h3 {
  font-weight: bold;
  font-size: rem(18);
  line-height: rem(22);
}
h4 {
  font-weight: bold;
  font-size: rem(16);
  line-height: rem(20);
}
h5 {
  font-weight: bold;
  font-size: rem(14);
  line-height: rem(18);
}
p {
  font-size: rem(16);
  line-height: rem(22);
}
.dividerTextLarge {
  font-size: rem(13);
  font-weight: bold;
  letter-spacing: rem(1);
  text-transform: uppercase;
  color: color('gray');
}
.dividerTextSmall {
  font-size: rem(12);
  font-weight: bold;
  letter-spacing: rem(1);
  text-transform: uppercase;
  color: color('gray');
}
.textSmall {
  font-size: rem(14);
  color: color('gray');
  margin-bottom: rem(5);
  font-weight: 500;
  line-height: rem(20);
  @include to(m) {
    line-height: rem(10);
    font-size: rem(10);
  }
}
// .textCentered {
//   text-align: center;
// }

// JEET HELPER CLASSES
// .col_1_2 {
//   @include span(1/2);
// }
// .col_1_1 {
//   @include span(1);
// }
// .col_1_3 {
//   @include span(1/3);
//   min-width: 250px;
// }
// .col_2_3 {
//   @include span(2/3);
// }
// .col_1_6 {
//   @include span(1/6);
// }
// .col_2_6 {
//   @include span(2/6);
// }
// .col_1_8 {
//   @include span(1/8);
// }
// .col_7_8 {
//   @include span(7/8);
// }
// .col_4_10 {
//   @include span(4/10);
// }
// .col_6_10 {
//   @include span(6/10);
// }

// DISPLAY
// .display_ib {
//   display: inline-block;
// }
// .display_i {
//   display: inline;
// }
// .display_b {
//   display: block;
// }

// CARDS
.compCardContainer {
  display: static;
  background-color: color('white');
  border: 1px solid color('light gray');
  border-radius: rem(2);
  margin-bottom: rem(18);

  // margin: rem(30) rem(0) rem(0) rem(0);
  @include to(l) {
    // margin: rem(20px 0);
  }

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &.smallMarginsHorizontal {
    // margin: rem(8) rem(15) rem(0) rem(15);
  }
  &.smallMarginsGrid {
    // margin: rem(10) rem(10) rem(0) rem(0);
  }
  &.confirmJobCompCardContainer {
    padding: rem(20 20 40 20);
  }
  .compCardContent {
    // display: inline-block;
    display: flex; // nc
    justify-content: space-between; // nc
    padding: rem(15 30 15 30);
    width: 100%;
    @include to(m) {
      flex-direction: column;
      padding: rem(20 10) !important;
    }
    &.noPaddingBottom {
      padding-bottom: 0;
    }
    &.noPaddingTop {
      padding-top: 0;
    }
  }
  .compCardTitleContainer {
    background-color: color('background');
    padding: rem(12 30);
    @include to(m) {
      padding-left: rem(10);
    }
  }
  .compCardHeaderContainer {
    padding: rem(20);
    border-bottom: 1px solid color('light gray');
  }
  .compCardLabelContainer {
    padding: rem(20);
    border: 1px solid color('light gray');
  }
}
// .compCardContainer:hover {
//   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
// }
.matchCompCardContent {
  @include to(l) {
    // padding: rem(20);
  }
}
.p20 {
  padding: rem(20);
}
.page {
  width: 100%;
  // max-width: rem(1280);
  margin: 0 auto;
  padding: rem(150 30 100 30);
  position: relative;
  @include to(l) {
    padding: rem(112 0 0 0);
  }
  &.withoutSecondNav {
    padding: rem(60 30 0 30) !important;
  }
}
.fixedCard {
  position: sticky;
  top: 120px;
}
.verticalAlign {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.link {
  color: color('blue');
  cursor: pointer;
  cursor: hand;
}
.pullRight {
  float: right;
}
.pullLeft {
  float: left;
}

.alert {
  &.green {
    background-color: color('light green');
    color: color('dark green');
    &.small {
      border: rem(1) solid color('green');
      border-radius: rem(2);
    }
  }
  &.blue {
    background-color: color('light blue');
    color: color('blue');
    &.small {
      border: rem(1) solid color('blue');
      border-radius: rem(2);
    }
  }
  &.red {
    background-color: color('light red');
    color: color('dark red');
    &.small {
      border: rem(1) solid color('red');
      border-radius: rem(2);
    }
  }
  &.big {
    padding: 20px;
  }
  &.small {
    padding: 2px;
    padding-bottom: 5px;
    font-size: rem(10);
    margin-left: rem(10);
  }
}
.cursor_pointer {
  cursor: pointer;
}
.mb_30 {
  margin-bottom: rem(30);
}
.inputTitle {
  color: color('dark blue');
  font-weight: bold;
  font-size: rem(14);
  margin-bottom: rem(8);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  // white-space: nowrap;
  max-width: 550px;
  @include to(m) {
    font-size: rem(12);
  }
}
.fieldWrapper {
  padding: rem(0 10 18 0);
}
.fieldError {
  font-size: rem(12);
  color: color('red');
}

.additionalInput {
  display: inline-block;
  width: rem(200);
  margin: rem(0 0 0 20);
  vertical-align: top;
}

// Alsyke DR edits

@mixin cf() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

.premiumPill {
  background: color('light green') !important;
  color: color('green');
  font-size: 10px;
}

.selectInputStyles__single-value {
  font-size: rem(14) !important;
  color: color('dark gray') !important;
}
.selectInputStylesContainer {
  font-size: rem(14) !important;
  color: color('dark gray') !important;
}
