@import "src/styles/_base.module.scss";
.kanbanColumn {
  margin: -15px;
  margin-bottom: -15px;
  background: #f6f8fa;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
}
.countCandidates {
  @include defaultLabelStyle;
  color: color('dark gray');
  font-family: $font-proxima;
  font-weight: 600;
}
.vertIcon {
  color: color('dark gray') !important;
  background: none;
  border: none;
}
