@import "src/styles/_base.module.scss";
$color1: color('white');
$color2: color('blue');

.radio {
  color: color('dark gray');
  .radioLabel {
    @include to(m) {
      font-size: rem(12) !important;
    }
  }
  .innerRadio {
    display: inline-block;
    margin-top: rem(11);
  }
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radioLabel {
      font-size: rem(14);

      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid color('light gray');
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: rem(9);
        margin-top: rem(2);
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radioLabel {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
          border: solid 1px color('light gray');
        }
      }
    }
    &:focus {
      + .radioLabel {
        &:before {
          outline: none;
        }
      }
    }
    &:hover {
      + .radioLabel {
        &:before {
          border: 1px solid color('gray');
        }
      }
    }
    &:disabled {
      + .radioLabel {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radioLabel {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.fieldError {
  font-size: rem(12);
  color: color('red');
}
