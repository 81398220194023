@import "src/styles/_base.module.scss";
.projectsDropdownWrapper {
  &.inline {
    display: inline-block;
  }
  padding-top: 0;
  :global {
    .dropdown:hover {
      background: red !important;
    }
    .menu-items-scroll {
      padding: rem(5 5 0 5);
    }
    .card-title {
      padding: rem(10);
      border: 1px solid color('light gray');
      margin: rem(5);
    }
    [aria-expanded='true'] {
      background: #fff !important;
    }
    [aria-haspopup='true'] {
      cursor: pointer;
      &:hover {
        background: #fff !important;
        .card-title {
          border: 1px solid color('gray');
        }
      }
    }
  }
}

.projectsPipelineWrapper {
  :global {
    padding-top: 0;
    .card-title {
      padding: rem(10);
      margin: rem(5);
    }
    .project {
      border-bottom: 1px solid rgba(color('light gray'), 0.5);
    }
  }
}

.noProjectWarning {
  padding: 0.5rem;
  color: #b5bdc2;
  font-size: 0.875rem;
  margin: 0.3125rem 0;
  text-align: center;
}
.applicantProjects {
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #dce0e3;
  font-family: $font-proxima;
  cursor: pointer;
  h3 {
    font-weight: 600 !important;
  }
}
