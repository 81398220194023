@import "src/styles/_base.module.scss";
.coverLetterWrapper {
  width: 100%;
  display: inline-block;
  margin-bottom: 50px;
  .inner {
    margin: 0 auto;
    width: 90%;
    max-width: rem(800);
    margin-top: 15%;
    background: color('white');
    border: rem(1) solid color('light gray');

    .header {
      border-bottom: rem(1) solid color('light gray');
      padding: rem(10);
      display: flex;
      justify-content: space-between;
      width: 100%;

      .avatar {
        float: left;
        border-radius: 50%;
        height: rem(45);
        width: rem(45);

        .avatar_text {
          font-size: rem(40);
        }
      }

      .text {
        float: left;
        display: inline-block;
        padding: rem(5 10);

        .name,
        .description {
          line-height: rem(18);
        }
        .description {
          color: color('gray');
          font-size: rem(14);
        }
      }
      .download {
        float: right;
      }
      .close {
        float: right;
      }
    }
    .body {
      padding: rem(55 100);

      .content {
        font-family: $font-serif;
        color: color('dark gray');
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }
}
