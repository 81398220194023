@import "src/styles/_base.module.scss";
.table {
  border: 1px solid #e7eaec;
  box-shadow: 1px 1px 8px 0px rgba(202, 202, 202, 0.75);
  -webkit-box-shadow: 1px 1px 8px 0px rgba(202, 202, 202, 0.75);
  -moz-box-shadow: 1px 1px 8px 0px rgba(202, 202, 202, 0.75);
  font-family: $font-proxima;
  th {
    background: white !important;
    color: color('dark blue');
    border-right: 1px solid #e5e8eb;
    border-bottom: 1px solid #e5e8eb !important;
    vertical-align: middle;
    font-weight: bold;
    font-size: 11pt;
  }
  td {
    border-right: 1px solid #e5e8eb;
    vertical-align: middle;
  }
  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    // clip-path: polygon(34% 70%, 100% 16%, 33% 100%, 0 37%);

    transform: scale(0);
    // transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em color('blue');
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1.4);
  }
  .expansionRow {
    position: absolute;
    left: 0px;
    width: 100%;
    background: white !important;
    background-color: white !important;
    color: black !important;
    font-weight: 600 !important;
    border-left: 1px solid #e5e8eb;
    font-family: $font-proxima;
    padding-bottom: 3px !important;
    z-index: 2;
  }
}
.tableStriped > tbody > tr:nth-of-type(2n + 1) > td {
  background: color('background') !important;
  color: var(--bs-table-striped-color);
  &.expansionRow {
    background: white !important;
  }
}
.tableStriped > tbody > tr:nth-of-type(2n + 0) > td {
  // background: #fff !important;
  color: var(--bs-table-striped-color);
}

.jobApplicantsTable {
  th {
    padding: 16px 14px !important;
  }
  td {
    color: color('dark gray') !important;
    font-weight: 600;
    padding: 14px !important;
  }
  td:first-child {
    min-width: 85px;
    span:first-child {
      margin-right: 5px;
    }
  }
  td:nth-child(4) {
    font-weight: 600;
    // color: color('dark blue') !important;
  }

  @include to(m) {
    font-size: 12px;
    button {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
.table > :not(:first-child) {
  border-top: 0 !important;
}

.expansionRow {
  position: absolute;
  left: 50px;
  width: calc(100% - 50px);
  background: white !important;
  background-color: white !important;
  color: black !important;
  font-weight: 700 !important;
  border-left: 1px solid #e5e8eb;
}
