@import "src/styles/_base.module.scss";

.confirmationWrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 111111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationModal {
  min-width: rem(350);
  max-width: rem(550);
  height: auto;
  background: #fff;
  border: rem(1) solid color('light gray');
  box-shadow: 0 rem(1) rem(15) rgba(color('gray'), .5);
  border-radius: rem(2);
  position: relative;
  > div {
    display: block;
    position: static;
  }
}

.confirmationBody {
  width: 75%;
  margin: rem(30) auto rem(10);
  text-align: center;
  h4 {
    color: color('gray');
    font-size: rem(14);
    text-transform: uppercase;
  }
}
.confirmationActions {
  text-align: center;
  padding: rem(20 0);
  :global {
    .form-elements {
      margin: rem(0 5);
    }
  }
}
