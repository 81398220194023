@import "src/styles/_base.module.scss";
.greyText {
  color: color('gray');
}
.blueIcon {
  color: color('blue');
}
.greenIcon {
  color: color('green');
}
