@import "src/styles/_base.module.scss";
@mixin menuItem {
  position: relative;
  //   @include cf;
  a {
    color: color('dark blue');
    cursor: pointer;
    display: block;
    padding: rem(10);
    -webkit-font-smoothing: antialiased;
    font-size: rem(14);
    svg {
      color: color('light gray');
      margin-left: rem(5);
    }
    &:hover {
      color: color('dark blue');
      background: color('light blue');
      svg {
        color: color('dark blue');
      }
    }
  }
  &.menu-item-disabled {
    a {
      color: color('gray');
      cursor: not-allowed;
      &:hover {
        color: color('gray');
        background: color('mild');
      }
    }
  }
}

:global {
  .navigation {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
  }
  .header {
    position: fixed;
    width: 100%;
    top: rem(50);
    left: 0;
    z-index: 19;
  }
  .filters-sidebar .header {
    z-index: 20 !important;
    margin-left: 305px;
  }
  .filters-sidebar .header .navbar .float-button {
    transform: rotate(90deg);
  }
  .search-page .sidebar {
    margin-top: rem(-60);
  }
}

.navMenu {
  appearance: none;
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  line-height: rem(20);
  outline: none;
  cursor: pointer;
  &[type='button'] {
    appearance: none;
  }
}

.navBarItem {
  float: left;
  margin-right: rem(10);
  font-size: rem(14);
  :global {
    .card-titles svg {
      vertical-align: middle !important;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.navBar {
  //   @include cf;
  .navBarLeft {
    float: left;
    // @include cf;
  }
  :global {
    .navitem > a,
    .navitem > .dropdown {
      color: color('gray');
      appearance: none;
      background: none;
      border: none;
      display: block;
      margin: 0;
      outline: none;
      cursor: pointer;
      padding: rem(15 10);
      line-height: rem(20);
    }
  }
  &.mainmenu {
    padding: rem(0 20);
    background: #384677;
    box-shadow: 0 0 rem(2) rgba(color('dark blue'), 0.2);
    .left {
      :global {
        a.navitem,
        span.labeled-title {
          opacity: 0.5;
        }
      }
    }
    :global {
      .navitem > a,
      .navitem > .dropdown {
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        svg {
          opacity: 1;
        }
        .labeled-count {
          font-size: 100%;
          color: #6d789e;
          background: #2f3d6b;
        }
        &[aria-expanded='true'] {
          background: #2e3c6c !important;
          .labeled-count {
            font-size: 100%;
            color: #647097;
            background: #273461;
          }
        }
      }
    }
  }
  &.submenu {
    position: relative;
    height: rem(60);
    padding: rem(0 20);
    background: #fff;
    box-shadow: inset 0 rem(-1) 0 color('light gray');
    .hasDropdown:after {
      border-top-color: color('gray');
    }
    .active {
      box-shadow: inset 0 rem(-3) 0 color('blue');
      :global {
        .labeled-title {
          color: color('blue');
        }
        .labeled-count {
          color: color('blue') !important;
          background: color('light blue') !important;
        }
      }
    }
    .cardNav {
      padding: rem(10);
    }
    :global {
      .navitem > a,
      .navitem > .dropdown {
        padding: rem(20 0);
        margin: rem(0 10);
        line-height: rem(20);
        font-weight: 500;
        font-size: rem(16);
        -webkit-font-smoothing: antialiased;
        color: color('dark gray');
        .labeled-count {
          color: color('gray');
          background: rgba(color('light gray'), 0.5);
        }
        &:hover {
          color: color('blue');
        }
      }
    }
  }
}

.navBarSegment {
  //   @include cf;
  &.left {
    position: absolute;
    top: 0;
    left: rem(20);
  }
  &.right {
    position: absolute;
    top: 0;
    right: rem(20);
    :global {
      .navitem button:not(.dropdown) {
        color: color('gray');
      }
    }
  }
  &.user {
    float: right;
    :global {
      .navitem > .dropdown {
        padding: rem(10);
        border-right: rem(1) solid #2d3a69;
      }
    }
  }
}

.menuDropdown {
  min-width: rem(320);
  background: #fff;
  border: rem(1) solid color('light gray');
  border-radius: rem(3);
  box-shadow: 0 rem(2) rem(15) rgba(color('dark gray'), 0.3);
  padding: rem(5 0);
  &[data-reactroot] {
    margin-top: rem(3);
  }
  &.hasHeading {
    padding-top: 0;
  }
  :global {
    .section-title {
      box-shadow: none !important;
    }
    .dropdown {
      box-shadow: none;
      padding: 0;
      border: none;
      position: relative;
      appearance: none;
      a {
        cursor: pointer;
        display: block;
        padding: rem(10);
        color: color('dark blue');
        font-weight: 500;
        font-size: rem(14);
      }
      svg {
        position: absolute;
        top: 50%;
        right: rem(10);
        margin-top: rem(-8);
        color: color('gray');
        // transform: rotate(-90deg);
      }
      &:hover {
        a,
        svg {
          color: color('dark blue');
        }
      }
    }
    .menu-item {
      @include menuItem;
    }
    .menu-item-slot {
      display: block;
      padding: rem(5 10 10 10);
      :global {
        label:not(.checkbox-label) {
          padding-top: rem(6);
          padding-bottom: rem(6);
        }
        label.checkbox-label {
          line-height: rem(20);
        }
        .form-element input {
          padding-top: rem(6);
          padding-bottom: rem(6);
        }
      }
    }
  }
}

:global {
  .main-menu-dropdown[data-reactroot] {
    padding-top: rem(5);
    padding-bottom: rem(5);
  }
}

.subMenu {
  min-width: rem(250);
  padding: rem(10 0);
  margin-top: rem(-10);
  margin-left: rem(-5);
  z-index: 111;
  background: #fff;
  border: rem(1) solid color('light gray');
  box-shadow: 0 rem(2) rem(15) rgba(color('dark gray'), 0.3);
  :global {
    .menu-item {
      @include menuItem;
    }
    .menu-item-slot {
      display: block;
      padding: rem(0 10);
    }
  }
}

.menuDivider {
  border-top: rem(1) solid color('mild');
  margin: rem(5 0 10 0);
}
.menuAction {
  padding: rem(0 10 5 10);
  :global {
    .form-elements,
    .form-element {
      margin: 0;
      font-size: rem(14) !important;
    }
  }
}

.dropdownTrigger {
  position: relative;
  padding-right: rem(20);
  //   @include cf;
  > div {
    float: left;
  }
  > svg {
    position: absolute;
    top: 50%;
    margin-top: rem(-10);
    right: 0;
    opacity: 0.5;
  }
  &:hover {
    > svg {
      opacity: 1;
    }
  }
  :global {
    .sb-avatar {
      display: block !important;
    }
  }
  &.noCaret {
    padding-right: 0;
  }
}

.menuItemsScroll {
  max-height: rem(310);
  overflow-y: auto;
}

.menuItemCard {
  :global {
    .card-titles a {
      padding: rem(5 10) !important;
      h3 {
        font-size: rem(14);
        font-weight: 500;
      }
      &:hover h3 {
        color: color('dark blue') !important;
      }
    }
  }
}

.cogIcon svg {
  color: color('gray');
}

.navBarItem {
  position: relative;
}

.mainmenu {
  .left {
    .highlight {
      :global {
        a.navitem,
        span.labeled-title {
          color: color('white');
          opacity: 1;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        border-left: rem(5) solid transparent;
        border-right: rem(5) solid transparent;
        border-bottom: rem(7) solid color('white');
        width: 0;
        height: 0;
        bottom: 0;
        left: calc(50% - 4px); //can't use rem inside calc
      }
    }
  }
}
