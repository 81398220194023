@import "src/styles/_base.module.scss";
.role {
  padding: rem(2 4);
  line-height: rem(20);
  border-radius: rem(3);
  font-size: rem(10);
  text-transform: uppercase;
  font-weight: 700;
  background: color('mild');
  color: color('gray');
}
.Admin {
  background: rgba(color('red'), 0.3);
  color: color('red');
}
.Senior {
  background: color('light blue');
  color: color('blue');
}
.Junior {
  background: color('light gray');
  color: color('dark gray');
}
