@import "src/styles/_base.module.scss";
.actionsBar {
  position: absolute;
  top: 57px;
  width: 100%;
  margin-left: -15px;

  .contextMenu{

    .columnInput{


      input[type='checkbox']::before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        // clip-path: polygon(34% 70%, 100% 16%, 33% 100%, 0 37%);
    
        transform: scale(0);
        // transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em color('blue');
        /* Windows High Contrast Mode */
        background-color: CanvasText;
      }
    
      input[type='checkbox']:checked::before {
        transform: scale(1.4);
      }
      
    
    }

  }


}


.resultsCount {
  margin-bottom: rem(10);
}

.resultsCountHeading {
  color: color('gray');
  font-size: rem(14);
}

.active svg {
  fill: color('blue');
}

.showColumnsDropdown {
  :global {
    .menu-item-slot {
      padding: rem(10);
      border-bottom: 1px solid rgba(color('light gray'), 0.2);
      &:last-child {
        border: none;
        padding-bottom: rem(5);
      }
    }
  }
}

.ReactTags :global {
  .ReactTags__tagInput {
    display: flex;
    justify-content: center;
    padding: 15px 15px 0 15px;
    input {
      width: 100%;
      border: 1px solid color('light gray');
      padding: 3px 10px;
    }
  }
  .ReactTags__selected {
    display: flex;
    gap: 15px;
    padding: 15px;
    padding-top: 10px;
    max-width: 318px;
    flex-wrap: wrap;

    span.ReactTags__tag {
      padding: rem(5);
      border: 1px solid color('light blue');
      border-radius: rem(2);

      color: color('blue');
      line-height: rem(18);
      font-size: rem(14);
      display: flex;
      align-items: center;
      button {
        border: none;
        background-color: transparent;
        color: color('blue');
        margin-left: 10px;
        font-size: 16px;
        padding: 0;
      }
    }
  }
}
