@import "src/styles/_base.module.scss";
.doubleRightPadding {
  padding-right: rem(20);
}
.publishedOn {
  background-color: color('background');
  padding: rem(10);
  border-radius: 5px;
  color: color('gray');
  white-space: nowrap;
}

.jobSubHeading {
  display: inline-block;
  padding: rem(9 0 0 5);
}

.jobPostWrapper {
  padding-left: rem(10);
}
.jobIndicatorFieldWrapper {
  margin: rem(0 0 15 15);
}
.colorFieldWrapper {
  display: inline-block;
  margin: rem(5 0 0 10);
}
.jobIndicatorFormWrapper {
  margin-top: rem(10);
  .avatar_dot {
    width: auto;
    display: inline-block;
    float: left;
  }
  .acronymComp {
    padding-top: rem(10);
  }
}
.submitButtonWrapper {
  margin-top: 106px;
  margin-bottom: 20px;
}
.jobPostStatusContentWrapper {
  margin-bottom: rem(20);
  button {
    padding-left: rem(14) !important;
    padding-right: rem(14) !important;
  }
}
.jobPublishedOnwrapper {
  padding-right: 10px;
}

@media screen and (max-width: 1350px) {
  .stack {
    // @include span(1);
    // @include stack();
    padding-left: 0;
    padding-right: 0;
  }
  .submitButtonWrapper {
    margin-top: 30px !important;
  }
}

.sectionLabel {
  padding: rem(5 0);
  color: color('dark blue');
  float: left;
  margin-right: rem(5);
}
.sectionInput {
  padding: rem(5 0);
  width: rem(200);
  float: left;
  margin-right: rem(20);
}
.sectionAction {
  float: left;
}
.actions {
  @include cf;
  float: right;
}
.label {
  width: 100%;
  display: inline-block;
  text-align: right;
  vertical-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.input {
  width: rem(190);
  display: inline-block;
}

.fieldError {
  font-size: rem(12);
  color: color('red');
}

.contentArea {
  margin-top: 20px;
  display: flex;
  box-shadow: 0px 0px 3px lightgrey;
  border-radius: 5px;
  background-color: color('background');
  font-family: $font-proxima;
  .tabs {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 300px;
    border-right: 1px solid lightgrey;
    background-color: #fff;
    @include to(s) {
      min-width: 100%;
    }
    ul {
      padding: 0;
      list-style-type: none;
      margin: 0;
      li {
        padding: 15px 20px;
        cursor: pointer;
        display: flex;
        gap: 20px;
        font-weight: 600;
        font-size: rem(14);
        align-items: center;
        color: color('dark gray');
        border-bottom: 1px solid color('light gray');
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #f2f2f2;
          color: #000;
        }
        &[class*='active'] {
          color: color('blue');
        }
      }
    }
  }
  .tabsContent {
    padding: rem(32) rem(24);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    p {
      color: color('dark gray');
    }
    .tabPane {
      padding: 30px;
      padding-bottom: 100px;
      p {
        color: grey;
      }

      display: none;
      transition: all 0.3s ease;
      &[class*='active'] {
        display: block;
        width: 100%;
      }
    }
  }
}

.rolesList {
  margin-top: 20px;
}
.settingsMain {
  padding-top: 80px;
}
