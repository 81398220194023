@import "src/styles/_base.module.scss";
.cardWrapper {
  text-align: center;
  padding: rem(20);
  &.isCard {
    padding: rem(40 20);
    max-width: rem(300);
    background: #fff;
    border-radius: rem(3);
    border: rem(1) solid color('light gray');
    box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  }
  .cardIcon {
    background: color('light blue');
    padding: rem(5);
    border-radius: 50%;
    color: color('blue');
    width: rem(40);
    height: rem(40);
    margin: 0 auto;
  }
  .cardTitle {
    margin-top: rem(20);
  }
  .cardDescription {
    color: color('gray');
    font-size: rem(14);
    line-height: 1.4;
  }
}
