@import "src/styles/_base.module.scss";
.actionWrapper {
  display: flex;
  align-items: center;
  @include to(m) {
    justify-content: center;
    margin-top: rem(10);
  }
}
.actionContent {
  display: inline-block;
}
.ellipsisButton {
  display: inline-block;
  @include to(m) {
    svg {
      height: 15px;
      width: 15px;
    }
  }
  // margin: 0 rem(20) rem(18) rem(10);
}
