@import "src/styles/_base.module.scss";
.cardNav {
  margin: rem(7 0 8);
}

.backLink {
  border-right: rem(1) solid color('light gray');
  a {
    font-size: rem(16) !important;
    font-weight: 500 !important;
    color: color('gray') !important;
    svg {
      margin-right: rem(0);
      // margin-top: rem(-1);
      color: color('gray') !important;
    }
    &:hover {
      color: color('blue') !important;
      svg {
        color: color('blue') !important;
      }
    }
  }
}

.cancelLink {
  a {
    color: color('blue') !important;
    font-weight: 500;
  }
}

.search {
  :global {
    .navbar {
      background: none !important;
      box-shadow: none !important;
    }
  }
}

.filtersButton {
  margin-top: rem(10);
  position: relative;
  left: rem(0);
}
.backlinkButton,
.backlinkMobile {
  display: inline-flex;
  align-items: center;
  border: 0;
  border-right: rem(1) solid color('light gray');
  padding: rem(18) rem(10);
  font-size: rem(16) !important;
  font-weight: 500 !important;
  color: color('gray') !important;
  background-color: transparent;
  svg {
    margin-right: rem(3);
    // margin-top: rem(-1);
    color: color('gray') !important;
  }
  &:hover {
    color: color('blue') !important;
    svg {
      color: color('blue') !important;
    }
  }
}
.backlinkMobile {
  border-right: none;
  padding: rem(10);
}
.leftNav {
  display: flex;
  gap: 30px;
}
