@import "src/styles/_base.module.scss";
.searchesContainer {
  // padding: rem(0 20);
}

.searchesWrapper {
  max-width: rem(960);
  margin: 15px auto;
  position: relative;
  @include cf;
  :global {
    .form-column:first-child {
      //   @include col(4/5);
    }
  }
  .savedSearchesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .searchesTitle {
    // width: rem(150);
    // margin-top: rem(5);
    text-align: left;
    h5 {
      // font-size: rem(14);
      // font-weight: 600;
      color: color('dark blue');
      margin: 0;
      line-height: rem(16);
    }
  }
  .searchesContent {
    // margin-left: rem(150);
    display: flex;
    justify-content: start;
    flex: 1;
    padding-bottom: 15px;
    margin-bottom: -15px;
    border-bottom: rem(1) solid color('light gray');
    margin-left: 20px;
  }
  .recruiter {
    // padding: rem(5 20 5 0);
    position: relative;
    // border-bottom: rem(1) solid color('light gray');
    // @include cf;
    display: none;
    &.show {
      display: flex;
      width: 100%;
      gap: 15px;
    }
    .expand {
      position: absolute;
      top: 0;
      right: 0;
      margin: rem(10 0);
      color: color('gray');
      cursor: pointer;
      &:hover {
        color: color('dark gray');
      }
    }
    :global {
      .sb-avatar {
        float: left;
      }
      .tags {
        height: rem(30);
        overflow: hidden;
        // margin-left: rem(40);
        &.expanded {
          height: auto;
          overflow: auto;
          display: flex;
        }
      }
    }
  }
  .showAll {
    color: color('gray');
    margin-top: rem(10);
    float: right;
    font-size: rem(14);
    cursor: pointer;
    &:hover {
      color: color('blue');
    }
  }
}

.noSearches p {
  margin: 0;
  line-height: rem(30);
  color: color('gray');
  font-size: rem(14);
  padding: rem(0 0 0 40);
}

:global {
  #tags {
    max-width: rem(960);
    margin: 0 auto;
  }
  .react-tagsinput-wrapper {
    // @include cf;
  }
  .react-tagsinput-tag {
    float: left;
    padding: rem(5);
    border: 1px solid red;
    margin: rem(0 5 5 0);
  }
}
