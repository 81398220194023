@import "src/styles/_base.module.scss";
.tagsWrapper {
  //   @include cf;
}

.tagWrapper {
  // float: left;
  display: flex;
  padding: rem(5);
  border: 1px solid color('light blue');
  border-radius: rem(2);
  margin: rem(0 5 5 0);
  color: color('blue');
  line-height: rem(18);
  font-size: rem(14);
  margin-left: 10px;
  svg {
    margin: rem(2);
    margin-left: rem(10);
    color: color('blue');
    // float: right;
  }
  &:hover {
    border-color: color('light blue');
    cursor: pointer;
  }
  a:hover {
    svg {
      color: color('red');
    }
  }
}
