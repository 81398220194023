@import "src/styles/_base.module.scss";
.statsWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  color: color('gray');
  gap: 1.5rem;
  @include from(m) {
    width: 500px;
  }
  @include to(m) {
    font-size: 10px;
    padding-top: 10px;
    justify-content: center;
    gap: rem(12);
    margin-top: 10px;
  }
}
.redExpiry {
  color: color('red');
  font-weight: 500;
}

.jobGrayStat {
  display: flex;
  gap: rem(3);
}

.jobBlueStat {
  color: color('blue');
  font-weight: 500;
  display: flex;
  gap: 5px;
  @include to(m) {
  }
}
