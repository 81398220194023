@import "src/styles/_base.module.scss";
.kanbanCard {
  min-width: 315px;
  padding: rem(10);
  border-radius: 2px;
  border: 1px solid #dee1e3;
  // box-shadow: 0px 1px 5px 4px rgba(0, 0, 0, 0.05);
  // -webkit-box-shadow: 0px 1px 5px 4px rgba(0, 0, 0, 0.05);
  // -moz-box-shadow: 0px 1px 5px 4px rgba(0, 0, 0, 0.05);
  //   max-width: rem(300);
  background: #ffffff !important;
  margin-bottom: 5px;

  .kanbanCardHeading {
    color: #232a3f;
    line-height: 1rem;
  }
  .kanbanCardDescription {
    color: #7c8b91;
    font-weight: 500;
    font-size: 13px;
  }
  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid #dee1e3;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    // clip-path: polygon(34% 70%, 100% 16%, 33% 100%, 0 37%);

    transform: scale(0);
    // transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em color('blue');
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }


  input[type='checkbox']:checked::before {
    transform: scale(1.4);
  }
  .attachment {
    color: #e8ebed;
  }
  .inbox {
    color: #e8ebed;
  }
}
.reactKanbanBoardWrapper {
  :global {
    .react-kanban-board {
      div {
        .react-kanban-column {
          background: #eff1f3;
          margin-right: 15px;
          position: relative;
        }
      }
    }
  }


}
.sidebarAdjust{

  margin-left: 0px;
}


.pageWrapperBin {
  width: 100%;
  margin: 0 auto;
  padding: rem(40 30 30 30);
  position: relative;
}

@media only screen and (min-width:700px) {
  .sidebarAdjust{
    margin-left: 320px;
  }
}

