@import "src/styles/_base.module.scss";
.contactConnectCard{
    padding: 10px;
    border: 1px solid color('gray');
    margin: 25px;
    

}
.descColor{
    color: color('gray');
    font-weight: 500;
    margin-top: 15px;
}