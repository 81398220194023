@import "src/styles/_base.module.scss";
.talentMedals {
  @include cf;
  text-align: center;
  margin: rem(20 0);
  .talentMedal {
    line-height: rem(40);
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    background: #f4f6f8;
    display: inline-block;
    margin-right: rem(10);
    &:last-child {
      margin-right: 0;
    }
  }
}

.note {
  color: color('light gray');
  margin: 0;
  padding: rem(20);
}

.notesForm {
  padding: rem(20);
}

.jobWrapper {
  .jobSection {
    @include cf;
    margin-left: rem(30);
    position: relative;
    .jobYear {
      width: rem(50);
      height: rem(50);
      border-radius: 50%;
      background: rgba(color('light gray'), 0.3);
      padding: rem(15 0);
      text-align: center;
      font-size: rem(12);
      line-height: rem(20);
      color: color('gray');
      font-weight: 700;
      letter-spacing: 1px;
      float: left;
      margin-left: rem(-26.5);
    }
    .jobData {
      margin-left: rem(40);
    }
  }
  .mainHeading {
    color: color('dark blue');
    font-size: rem(16);
  }
  .subHeading {
    font-size: rem(14);
    margin: rem(5 0);
    color: color('gray-blue');
  }
  .noteHeading {
    font-size: rem(13);
    margin: 0;
    color: color('dark blue');
  }

  &.isCurrent {
    .jobSection {
      border-left: rem(3) solid color('green');
      padding-bottom: rem(10);
      &:last-child {
        border-left-color: transparent;
      }
      &:before {
        content: '';
        display: block;
        width: rem(12);
        height: rem(12);
        background: #fff;
        border-radius: rem(50);
        position: absolute;
        top: 0;
        left: rem(-7.5);
        border: rem(3) solid color('green');
      }
      &:first-child:before {
        content: none;
      }
    }
    .jobYear {
      background: color('green');
      svg {
        color: #fff;
      }
    }
  }
}

.socialProfile {
  @include cf;
  .profileIcon {
    float: left;
    svg {
      display: block;
      color: color('dark gray');
    }
  }
  a,
  p {
    display: block;
    margin-left: rem(30);
    line-height: rem(20);
    color: color('dark gray');
    font-size: rem(14);
  }
  a {
    color: color('blue');
  }
}
.singleItem {
  padding: rem(10 20);
  border-bottom: rem(1) solid rgba(color('light gray'), 0.3);
}
.addressBlock {
  .profileIcon {
    float: left;
    svg {
      display: block;
      color: color('gray');
    }
  }
  .addressBlockData {
    margin-left: rem(30);
    a {
      color: color('blue');
      line-height: rem(20);
      display: block;
    }
    p {
      display: block;
      color: color('gray');
      font-size: rem(14);
      line-height: rem(20);
      margin: 0;
    }
  }
}

.talentJobs {
  color: color('dark gray');
  font-size: rem(14);
  line-height: rem(20);
}

.talentProfileNav {
  width: auto;
  border-bottom: rem(1) solid color('light gray');
  :global {
    .navbar {
      box-shadow: none;
      padding: 0;
    }
    a.navitem {
      font-weight: 500 !important;
    }
    .labeled-count {
      font-weight: 400 !important;
    }
  }
}

.talentTags {
  padding: rem(20);
  :global {
    .ReactTags__tag,
    input {
      border: 1px solid;
      padding: 2px 6px;
      background-color: transparent;
      display: inline-block;
      color: color('blue');
      margin-right: 5px;
      margin-bottom: 10px;
      button {
        border: none;
        color: color('blue');
        background-color: transparent;
      }
    }
    button {
      background-color: color('light blue');
      font-weight: 600;
      border: 1px solid;
      padding: 2px 6px;
      display: inline-block;
      color: color('blue');
    }
  }
}

.talentTagsFocused {
}
.talentTag {
  background: #fff;
  border: rem(1) solid color('light blue');
  padding: rem(5);
  display: inline-block;
  border-radius: rem(2);
  margin-right: rem(5);
  margin-bottom: rem(5);
  color: color('blue');
}
.talentTagLabel {
  max-width: rem(250);
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.talentTagRemove {
  cursor: pointer;
  svg {
    color: color('light blue');
    margin-left: rem(5);
    vertical-align: top !important;
  }
  &:hover svg {
    color: color('lava1');
  }
}

.talentTagInput {
  background: color('light blue');
  border: rem(1) solid color('light blue');
  padding: rem(5);
  width: rem(80);
  display: inline-block;
  border-radius: rem(2);
  margin-right: rem(5);
  margin-bottom: rem(5);
  color: color('blue');
  outline: none;
  &::placeholder {
    color: color('blue');
  }
}
