@import "src/styles/_base.module.scss";
.iconContainer {
  width: rem(40);
  height: rem(40);
  background: color('light blue');
  border-radius: 50%;
  margin-bottom: rem(20);
  display: inline-block;
  padding: rem(10);
  svg {
    color: color('blue');
  }
}

.overlayTitle {
  text-align: center;
  margin: rem(80 0 40);
}
