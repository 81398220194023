@import "src/styles/_base.module.scss";
.formRecruiterWrapper {
  margin: rem(10) !important;
  padding: rem(20) !important;
  border: 1px solid color('light gray');
  border-radius: rem(3);
  :global {
    .form-elements {
      margin-bottom: rem(10);
      &:last-child {
        margin-bottom: rem(0);
      }
    }
  }
}
