@import "src/styles/_base.module.scss";
.labeled {
  display: block;
  @include cf;
  .title {
    float: left;
  }
  .count {
    float: left;
    font-style: normal;
    padding: rem(2 7.5 0);
    line-height: rem(17);
    border-radius: rem(2);
    font-size: 75%;
    margin: rem(0 0 0 7);
  }
}
