@import "src/styles/_base.module.scss";
.cardTitleWrapper {
  @include cf;
  &.selectable {
    padding-left: rem(40);
    position: relative;
    .select {
      position: absolute;
      left: rem(10);
      top: 50%;
      margin-top: rem(-10);
    }
  }
  .titleLink {
    display: block;
  }
  .cardContent {
    position: relative;
    display: flex;
  }
  .title {
    color: color('dark blue');
  }
  .subhead {
    font-size: rem(14);
    color: color('gray');
    margin: 0;
    font-weight: 400;
    line-height: rem(20) !important;
  }
  .subheadAdmin {
    background: #ffd8d7;
    align-self: flex-start;
    padding: 0px 7px;
    border-radius: 4px;
    color: #d40000;
    font-weight: 600;
    font-size: rem(12);
    font-family: $font-proxima;
    line-height: 1.6;
    margin-top: 4px;
  }
  .subheadSenior {
    background: #d7eeff;
    color: color('blue');
    align-self: flex-start;
    padding: 0px 7px;
    border-radius: 4px;
    font-weight: 600;
    font-size: rem(12);
    font-family: $font-proxima;
    line-height: 1.6;
    margin-top: 4px;
  }
  .subheadJunior {
    background: #dde1e4;
    color: color('dark grey');
    align-self: flex-start;
    padding: 0px 7px;
    border-radius: 4px;
    font-weight: 600;
    font-size: rem(12);
    font-family: $font-proxima;
    line-height: 1.6;
    margin-top: 4px;
  }
  .icon {
    float: left;
    border-radius: 50%;
    background: color('light blue');
    text-align: center;
    svg {
      color: color('blue');
    }
  }
  .titleLink.hover {
    cursor: pointer;
    .title {
      color: color('dark blue');
    }
  }
  .titleLink.hover:hover {
    .title {
      color: color('blue');
    }
    .subhead {
      color: lighten(color('blue'), 20%);
    }
  }
  .titleLink:not(.hover):hover {
    .title {
      color: color('dark blue');
    }
    .subhead {
      color: color('gray');
    }
  }
  &.small {
    .titleLink {
      padding: 0;
    }
  }
  &.medium {
    .titleLink {
      padding: rem(5);
    }
  }
  &.large {
    .titleLink {
      padding: rem(10);
    }
  }
}

.cardHeadingPara {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
