@import "src/styles/_base.module.scss";
.overlayForm {
  border: rem(1) solid color('light gray');
  border-radius: rem(3);
  margin: rem(10);
  :global {
    .form-elements {
      margin-bottom: rem(10);
      &:last-child {
        margin-bottom: 0;
      }
      display: block;
    }
    .form-fieldset {
      padding: rem(10);
    }
  }
}
