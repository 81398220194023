@import "src/styles/_base.module.scss";
.login-form-inner {
  display: block;
  background: #fff;
  font-size: 1rem;
  width: 100%;
  line-height: 1.25rem;
  text-align: left;
  padding: 1.25rem;
}
.loginForm {
  width: rem(360);
  margin: rem(50) auto;

  h2 {
    font-size: rem(20);
    font-weight: 400;
    color: color('dark blue');
    text-align: center;
    margin: rem(20 0 30);
  }

  .errorMessage {
    border: rem(1) solid color('red');
    border-radius: rem(2);
    background: rgba(color('red'), 0.3);
    color: color('red');
    padding: rem(10);
    width: 100%;
    margin-bottom: rem(10);
    font-size: rem(14);
    line-height: rem(20);
  }

  .resetLink {
    float: right;
    color: color('blue');
    font-size: rem(14);
    line-height: rem(20);
    outline: none;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .terms {
    color: color('blue');
    font-size: rem(14);
    line-height: rem(20);
    outline: none;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  :global {
    .form-inner-wrapper {
      padding: rem(20);
    }
    .form-elements {
      margin-bottom: rem(10);
    }
  }
}
