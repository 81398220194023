@import "src/styles/_base.module.scss";

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#fff, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  &.fixed {
    position: fixed;
  }
  .modalClose {
    position: absolute;
    top: rem(10);
    right: rem(10);
    opacity: .7;
    cursor: pointer;
    svg {
      color: color('dark gray');
    }
    &:hover {
      opacity: 1;
    }
  }
}
