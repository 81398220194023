@import "src/styles/_base.module.scss";
.pipelineCard {
  margin-bottom: 10px;
  position: relative;
  padding: rem(24);
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 1px 1px 4px lightgrey;
  font-family: $font-proxima;

  .title {
    font-weight: 600;
    font-size: 14px;
    color: #2c3346;
    margin: 0;
  }
  .description {
    color: grey;
    font-size: 15px;
    margin: 0;
  }
  .btnEdit {
    background: none;
    border: none;
    color: color('blue');
    font-size: rem(14);
    font-weight: 600;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    cursor: pointer;
    outline: none;
    font-family: $font-proxima;
  }
  .pipebtnClose {
    background: none;
    border: none;
    color: color('#F6F8FA');
    font-size: rem(18);
    font-weight: 600;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    cursor: pointer;
    outline: none;
    font-family: $font-proxima;
  }
}
.pipelineForm {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  // animation: fade 1s linear forwards;
  // .btnDelete {
  //   position: absolute;
  //   top: 50px;
  //   right: 40px;
  //   border: none;
  //   background: none;
  //   color: #f93d3d;
  //   cursor: pointer;
  //   border-radius: 7px;
  //   z-index: 4;
  // }
}

// @keyframes fade {
//   from {
//     // opacity: 0;
//     visibility: hidden;
//     transform: translateY(0px);
//   }
//   to {
//     // opacity: 1;
//     visibility: visible;
//     transform: translateY(10px);
//   }
// }
