@import "src/styles/_base.module.scss";
.clientProfileWrapper {
  background: #fff;
  .clientCover {
    background: color('light blue');
    min-height: rem(166);
    margin-bottom: rem(-55);
    text-align: center;
    .imagePreview {
      display: block;
      // width: rem(362);
      height: rem(166);
      background-position: center center;
      background-size: cover;
    }
    button {
      font-size: rem(14);
      color: color('blue');
      svg {
        display: block;
        margin: rem(10) auto;
      }
      &:hover {
        color: rgba(color('blue'), 0.7);
      }
    }
    &.standalone {
      img {
        border-radius: rem(2 2 0 0);
      }
    }
  }
  .clientLogo {
    position: relative;
    z-index: +1;
    width: rem(110);
    height: rem(110);
    margin: 0 auto rem(-55);
    text-align: center;
    border-radius: 50%;
    // background: color('light blue');
    button {
      color: #fff;
      font-size: rem(14);
      margin: 0;
      height: rem(110);
      white-space: normal !important;
      border-radius: 50%;
      svg {
        display: block;
        margin: rem(10) auto;
      }
      &:hover {
        color: color('light blue');
      }
    }
    .logoPreview {
      display: block;
      width: rem(110);
      height: rem(110);
      border-radius: 50%;
      background-position: center center;
      background-size: cover;
    }
  }
  .clientBody {
    position: relative;
  }
  .clientNameWrapper {
    padding: rem(80) 0 rem(20);
    width: 75%;
    margin: 0 auto;
    text-align: center;
    h2 {
      color: color('dark blue');
    }
  }
  .clientName {
    color: color('dark blue');
  }
  .clientLocation {
    font-size: rem(14);
    margin: 0;
    color: color('gray');
  }
  .clientSettingsWrapper {
    position: absolute;
    top: rem(10);
    right: rem(10);
    svg {
      color: color('light gray');
    }
    &:hover {
      svg {
        color: color('blue');
      }
    }
  }
  :global {
    .section-title {
      box-shadow: none;
      padding-top: rem(10);
      padding-bottom: rem(10);
    }
  }
}
.clientNameLink:hover h2 {
  color: color('blue');
  cursor: pointer;
}
.clientLogoLink {
  transition: all ease 0.2s;
  display: block;
  border-radius: 50%;
  width: rem(110);
  height: rem(110);
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  cursor: pointer;
  &.edit {
    background-color: color('blue');
  }
  .clientLogoImage {
    opacity: 0;
  }
}
.clientLogoUpload {
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  display: block;
  width: rem(110);
  height: rem(110);
  cursor: pointer;
  background: color('blue');
  &.haslogo {
    background: color('blue');
    img {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 1;
      object-fit: contain;
      background: white;
    }
    &:hover {
      background-color: color('blue');
      img {
        opacity: 0.2;
      }
      :global {
        .form-elements {
          position: relative;
          z-index: +1;
          display: block;
        }
      }
    }
  }
}
.clientLogoLink:hover {
  transform: scale(1.05);
  img {
    width: 100%;
  }
}
.clientCardDashboard {
  min-width: rem(364);
  @include to(l) {
    min-width: rem(310);
  }
}
