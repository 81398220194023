@import "src/styles/_base.module.scss";
.section {
  margin-bottom: rem(40);
}

.sectionWrapper {
  padding-top: rem(10);
  // @include cf;
  :global {
    .form-inner-wrapper {
      border: rem(1) solid color('light gray');
      border-radius: rem(2);
    }
  }
}
.sectionHeaderWrapper {
  width: rem(200);
  float: left;
}
.sectionHeaderTitle {
  width: auto;
  h1 {
    line-height: rem(50);
    color: color('dark blue');
  }
}
.sectionSearch {
  padding: rem(5 0);
  width: rem(250);
  float: left;
  margin-right: rem(20);
}
.sectionAction {
  float: left;
}
.actions {
  // @include cf;
  // float: right;s
  display: flex;
}
.sectionHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // @include cf;
  h2 {
    float: left;
    line-height: rem(50);
  }
  :global {
    .float-button {
      float: right;
    }
  }
}

.iconWrapper {
  display: block;
  float: left;
  margin: rem(0 10 0 0);
  width: rem(30);
  height: rem(30);
  border-radius: 50%;
  background: rgba(color('blue'), 0.2);
  padding: rem(5);
  svg {
    color: color('blue');
  }
}

.subtitle {
  margin: 0;
  color: color('gray');
  font-size: rem(14);
}
