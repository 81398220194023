@import "src/styles/_base.module.scss";
.pageWrapper {
  width: 100%;
  margin: 0 auto;
  padding: rem(150 30 30 30);
  position: relative;
}
.pageWrapperBin {
  width: 100%;
  margin: 0 auto;
  padding: rem(40 30 30 30);
  position: relative;
}

.notSubMenuWrapper {
  padding: rem(90 30 30 30) !important;
}
