@import "src/styles/_base.module.scss";


.formSaveSearch {
  :global {
    .form-fieldset {
      padding: rem(10 10 0 10);
    }
    .form-elements {
      margin-bottom: rem(10);
      &:last-child {
        margin-bottom: 0
      }
    }
  }
}
