@import "src/styles/_base.module.scss";
.textLink {
  color: color('blue');
}
.searchFilters {
  max-width: rem(1280);
  margin: 0 auto;
  border-radius: rem(3);
  border: rem(1) solid color('light gray');
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  background: #fff;
  display: none;
  &.showFilters {
    display: block;
  }
  .experienceInput,
  .qualificationsInput {
    :global {
      .form-elements-wrapper {
        // @include cf;
        .form-element {
          //   @include col(1/5);
        }
      }
    }
  }
}

.filterMobile {
  @include to(m) {
    position: absolute;
    top: 10px;
    z-index: 999;
    right: 900px;
    transition: all 500ms;
  }
}
.filterMobileOpen {
  right: 0 !important;
}
.crossIcon {
  display: flex;
  align-items: center;
}

.filterLabel {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
}

.fieldWrapper {
  :global {
    label {
      margin-bottom: 10px;
    }
  }
}
.closeFilterMobile {
  margin-right: -20px;
}
