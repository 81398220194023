@import "src/styles/_base.module.scss";
.list {
  .listItem {
    text-align: left;
    padding: rem(10 20);
    border-bottom: rem(1) solid rgba(color('light gray'), 0.3);
    &:last-item {
      border-bottom: none;
    }
  }
}
