@import "src/styles/_base.module.scss";
.toggleArrow {
  float: right;
  margin-top: rem(-16);
  transform: rotate(90deg);
  transition: all ease 0.3s;
  &.isOpen {
    transform: rotate(-90deg);
  }
}
.visibleMobile {
  display: none !important;
  @include to(m) {
    display: block;
  }
}
@include to(m) {
  #mobileHeader {
    display: block !important;
    width: 270px;
    background-color: #2b375e;
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 999;
    border-left: 2px solid #1c2645;
    height: 100vh;
    overflow: auto;
    transition: right 200ms linear;
    &.isActive {
      right: -280px;
      display: none !important;
    }
    .accountOptions {
      border-bottom: 1px solid #202946;
      display: flex;
      width: 100%;
      margin: 0;
      .connections {
        border-right: 1px solid #202946;
      }
      .close {
        margin-left: auto;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #fff;
        padding: 11px 0 10px 0;
        width: 47px;
      }
    }
    nav {
      clear: both;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-family: $font-proxima;
          font-weight: 700;
          font-style: normal;
          font-size: 15px;
          line-height: 16px;
          border-bottom: 1px solid #202946;
          &.open {
            a {
              i {
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
          a {
            color: #fff;
            display: block;
            padding: 15px 13px 13px;
            position: relative;
            .activity {
              position: absolute;
              top: 50%;
              margin-top: -3px;
              right: 9px;
              width: 8px;
              height: 8px;
              -moz-border-radius: 50%;
              -webkit-border-radius: 50%;
              border-radius: 50%;
              background-color: #0d9bdc;
            }
            i {
              position: absolute;
              top: 50%;
              right: 8px;
              margin-top: -12px;
              font-size: 20px;
              line-height: 25px;
              color: #808baa;
              transition: all 240ms ease-out;
              -webkit-transition: all 240ms ease-out;
              -moz-transition: all 240ms ease-out;
              -ms-transition: all 240ms ease-out;
              -o-transition: all 240ms ease-out;
            }
          }
        }
        a {
          color: #fff;
          display: block;
          padding: 15px 13px 13px;
          position: relative;
          .activity {
            position: absolute;
            top: 50%;
            margin-top: -3px;
            right: 9px;
            width: 8px;
            height: 8px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-color: #0d9bdc;
          }
          i {
            position: absolute;
            top: 50%;
            right: 8px;
            margin-top: -12px;
            font-size: 20px;
            line-height: 25px;
            color: #808baa;
            transition: all 240ms ease-out;
            -webkit-transition: all 240ms ease-out;
            -moz-transition: all 240ms ease-out;
            -ms-transition: all 240ms ease-out;
            -o-transition: all 240ms ease-out;
          }
        }
        .activity {
          span {
            position: absolute;
            top: 50%;
            margin-top: -3px;
            right: 9px;
            width: 8px;
            height: 8px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-color: #0d9bdc;
          }
        }
        ul {
          display: none;
          border-top: 1px solid #202946;
          background-color: #242f53;
          padding-bottom: 5px;
          li {
            font-family: 'proxima_nova_rgregular';
            font-weight: normal;
            font-style: normal;
            border: 0;
            &:last-child {
              border: 0;
              &:last-child {
                border: 0;
              }
              a {
                color: #d4d9e8;
                padding: 17px 15px 12px 43px;
              }
            }
          }
        }
      }
    }
    .profile {
      padding: 15px 14px 7px;
      font-family: $font-proxima;
      .avatar {
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        float: left;
        margin-right: 9px;
        max-width: 50px;
        max-height: 50px;
      }
      .text {
        float: left;
        width: 75%;
        position: relative;
        top: -1px;
        h6 {
          margin: 0 0 -1px;
          font-family: $font-proxima;
          font-weight: bold;
          font-style: normal;
          font-size: 15px;
          line-height: 18px;
          a {
            color: #fff;
          }
          span {
            font-size: 11px;
          }
        }
        p {
          font-size: 13px;
          line-height: 18px;
          color: #fff;
          margin: 0;
        }
      }
    }
    .btn-wrapper {
      text-align: center;
      margin-top: 11px;
      padding: 0 15px 20px;
      .btn {
        padding-top: 9px;
        padding-bottom: 11px;
        display: block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #232e51;
        text-transform: none;
        font-size: 14px;
        font-family: 'proxima_nova_rgregular';
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0;
        i {
          font-size: 12px;
        }
      }
    }
  }
  .overlay {
    background-color: color('dark blue');
    opacity: 0.85;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 599;
  }
}
#mobileHeader .accountOptions .messages:hover,
#mobileHeader .accountOptions .notifications:hover,
#mobileHeader .accountOptions .connections:hover,
#mobileHeader .accountOptions .profile:hover {
  padding-bottom: 0;
}

.accountOptions {
  float: right;
  margin-right: 70px;
  font-size: 0;
}
.accountOptions .messages,
.accountOptions .notifications,
.accountOptions .connections,
.accountOptions .profile {
  float: left;
  position: relative;
}
.accountOptions .messages:before,
.accountOptions .notifications:before,
.accountOptions .connections:before,
.accountOptions .profile:before {
  content: '';
  width: 1px;
  height: 46px;
  background-color: #252f50;
  position: absolute;
  top: 0;
  left: 0;
}
.accountOptions .messages a,
.accountOptions .notifications a,
.accountOptions .connections a,
.accountOptions .profile a {
  display: block;
  height: 46px;
  padding: 0 28px 0 29px;
  color: #7a85a6;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
}
.accountOptions .messages a:hover,
.accountOptions .notifications a:hover,
.accountOptions .connections a:hover,
.accountOptions .profile a:hover {
  background-color: #36426b;
  color: #fff;
}
.accountOptions .messages a .icon,
.accountOptions .notifications a .icon,
.accountOptions .connections a .icon,
.accountOptions .profile a .icon {
  font-size: 20px;
  line-height: 30px;
  position: relative;
  top: 10px;
}
.accountOptions .messages a .icon span,
.accountOptions .notifications a .icon span,
.accountOptions .connections a .icon span,
.accountOptions .profile a .icon span {
  position: absolute;
  font-family: 'proxima_novasemibold';
  font-weight: normal;
  font-style: normal;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #f03434;
  font-size: 11px;
  line-height: 12px;
  color: #fff;
  top: -8px;
  left: 13px;
  padding: 2px 5px 1px;
  min-width: 17px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.accountOptions .notifications:hover .result {
  display: block;
}
.accountOptions .notifications a {
  padding: 0 30px 0 31px;
}
.accountOptions .notifications a .icon {
  font-size: 21px;
  top: 9px;
}
.accountOptions .notifications a .icon span {
  top: -6px;
  left: 11px;
}
.accountOptions .notifications .result {
  display: none;
  right: -48px;
  left: auto;
  top: 57px;
}
.accountOptions .notifications .result:before {
  left: auto;
  right: 77px;
}
.accountOptions .notifications .result header {
  padding: 12px 20px 11px;
}
.accountOptions .notifications .result header p {
  letter-spacing: 1.2px;
}
.accountOptions .notifications .result .link {
  height: 100%;
  padding: 0;
}
.accountOptions .notifications .result .link:hover {
  background: none;
}
.accountOptions .notifications .result .result-info {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
}
.accountOptions .notifications .result .result-info:first-child {
  padding-top: 18px;
  padding-bottom: 20px;
  padding-right: 15px;
}
.accountOptions .notifications .result .result-info:first-child h6 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
}
.accountOptions .notifications .result .result-info .text {
  top: -2px;
}
.accountOptions .notifications .result .result-info h6 {
  margin-bottom: 1px;
}
.accountOptions .notifications .result .result-wrapper .all {
  padding: 7px 19px 6px;
  color: #0d9bdc;
  height: auto;
}
.accountOptions .notifications .result .result-wrapper .all:hover {
  background: none;
}
.accountOptions .connections:hover .result {
  display: block;
}

.accountOptions .connections a {
  padding: 0 10px;
}
.accountOptions .connections a .icon {
  font-size: 21px;
}
.accountOptions .connections .number {
  font-family: 'proxima_novasemibold';
  font-weight: normal;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  color: #546088;
  background-color: #1d2645;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 5px 2px;
  position: relative;
  top: 6px;
  margin-left: 5px;
}
.accountOptions .connections .result {
  display: none;
  right: -48px;
  left: auto;
  top: 57px;
}
.accountOptions .connections .result:before {
  left: auto;
  right: 77px;
}
.accountOptions .connections .result header {
  padding: 12px 20px 11px;
}
.accountOptions .connections .result header p {
  letter-spacing: 1.2px;
}
.accountOptions .connections .result header .head-link {
  display: inline-block;
  height: auto;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #36426b;
  font-family: 'proxima_novasemibold';
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 7px 27px;
}
.accountOptions .connections .result header .head-link:hover {
  background-color: #36426b;
}
.accountOptions .connections .result header .head-link i {
  position: absolute;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  top: 7px;
  left: 11px;
}
.accountOptions .connections .result .link {
  height: 100%;
  padding: 0;
}
.accountOptions .connections .result .link:hover {
  background: none;
}
.accountOptions .connections .result .result-info {
  padding-top: 20px;
  padding-bottom: 16px;
  padding-right: 100px;
}
.accountOptions .connections .result .result-info:first-child {
  padding-top: 18px;
  padding-bottom: 16px;
  padding-right: 100px;
}
.accountOptions .connections .result .result-info:first-child h6 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
}
.accountOptions .connections .result .result-info .text {
  top: -1px;
}
.accountOptions .connections .result .result-info h6 {
  margin-bottom: -1px;
}
.accountOptions .connections .result .result-info .yes,
.accountOptions .connections .result .result-info .no {
  width: 30px;
  height: 30px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -18px;
  text-align: center;
  padding: 0;
  z-index: 200;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.accountOptions .connections .result .result-info .yes i,
.accountOptions .connections .result .result-info .no i {
  font-size: 21px;
  line-height: 30px;
}
.accountOptions .connections .result .result-info .no {
  border: 1px solid #c2e4f3;
  background-color: #e5f4fb;
  color: #0d9bdc;
  right: 20px;
}
.accountOptions .connections .result .result-info .no i {
  line-height: 27px;
}
.accountOptions .connections .result .result-info .yes {
  background-color: #0d9bdc;
  color: #fff;
  right: 55px;
}
.accountOptions .connections .result .result-wrapper .all {
  padding: 7px 19px 6px;
  color: #0d9bdc;
  height: auto;
}
.accountOptions .connections .result .result-wrapper .all:hover {
  background: none;
}
.accountOptions .messages:hover .result {
  display: block;
}
.accountOptions .messages .result {
  display: none;
  right: -196px;
  left: auto;
  top: 57px;
}
.accountOptions .messages .result:before {
  left: 50%;
  margin-left: -6px;
}
.accountOptions .messages .result header {
  padding: 13px 20px 10px;
}
.accountOptions .messages .result header p {
  letter-spacing: 1.2px;
}
.accountOptions .messages .result header .head-link {
  display: inline-block;
  height: auto;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #36426b;
  font-family: 'proxima_novasemibold';
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 7px 27px;
}
.accountOptions .messages .result header .head-link:hover {
  background-color: #36426b;
}
.accountOptions .messages .result header .head-link i {
  position: absolute;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  top: 7px;
  left: 11px;
}
.accountOptions .messages .result .link {
  height: 100%;
  padding: 0;
}
.accountOptions .messages .result .link:hover {
  background: none;
}
.accountOptions .messages .result .result-info {
  padding-top: 15px;
  padding-bottom: 13px;
  padding-right: 15px;
}
.accountOptions .messages .result .result-info:first-child {
  padding-top: 18px;
  padding-bottom: 13px;
  padding-right: 15px;
}
.accountOptions .messages .result .result-info:first-child h6 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
}
.accountOptions .messages .result .result-info .text {
  top: -2px;
}
.accountOptions .messages .result .result-info h6 {
  margin-bottom: 1px;
}
.accountOptions .messages .result .result-wrapper .all {
  padding: 4px 19px 9px;
  color: #0d9bdc;
  height: auto;
}
.accountOptions .messages .result .result-wrapper .all:hover {
  background: none;
}
.accountOptions .profile:after {
  content: '';
  width: 1px;
  height: 46px;
  background-color: #252f50;
  position: absolute;
  top: 0;
  right: 0;
}
.accountOptions .profile:hover nav {
  display: block;
  padding-top: 20px;
}
.accountOptions .profile:hover ul {
  display: block;
}
.accountOptions .profile a {
  padding: 0 11px;
}
.accountOptions .profile a img {
  position: relative;
  top: 10px;
}
.accountOptions .profile ul {
  display: none;
  position: absolute;
  width: 162px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #2b375e;
  top: 57px;
  right: 0;
  padding: 4px 0 8px;
}
.accountOptions .profile ul:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6.5px 7px 6.5px;
  border-color: transparent transparent #2b375e transparent;
  position: absolute;
  top: -7px;
  right: 16px;
}
.accountOptions .profile ul li {
  font-family: 'proxima_novasemibold';
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  display: block;
  margin: 0;
  -webkit-transition: background-color 200ms ease-out;
  -moz-transition: background-color 200ms ease-out;
  -ms-transition: background-color 200ms ease-out;
  -o-transition: background-color 200ms ease-out;
}
.accountOptions .profile ul li:hover {
  background-color: #36426b;
}
.accountOptions .profile ul li a {
  padding: 10px 21px 11px;
  display: block;
  color: #9da6c2;
  height: auto;
}
