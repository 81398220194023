@import "src/styles/_base.module.scss";
.teamCountWrapper {
  padding-top: 11px;
  gap: 10px;
  display: flex;
}
.teamCount {
  background: #b5bdc2;
  display: flex;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 600;
  font-family: $font-proxima;
  font-size: 14px;
}
.teamWrapper {
  display: flex;
  gap: 10px;
}
.teamMember {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
