@import "src/styles/_base.module.scss";
.avatar {
  img {
    width: rem(45);
    height: rem(45);
    border-radius: rem(20);
    display: block;
  }
  @include to(m) {
    width: rem(20);
    height: rem(20);
  }
}
.jobListingAvatar {
  @include to(m) {
    height: rem(25) !important;
    width: rem(25) !important;
  }
  img {
    border: 1px solid #e4e2e2 !important;
  }
}
